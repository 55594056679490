// src/pages/TokenInfo.js

import React, { useState, useEffect } from 'react';
import {
  Button,
  InputGroup,
  FormControl,
  Alert,
  Modal,
  Form,
  Spinner,
} from 'react-bootstrap';
import { FaCopy, FaCube, FaCoins, FaListOl, FaSortNumericUp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { copyToClipboard } from '../utils/clipboard';
import { Contract, ethers } from 'ethers'; 
import TokenABI from '../abis/TokenABI.json';
import { useWeb3 } from '../Web3Context';
import { toast } from 'react-toastify';

import styles from './TokenInfo.module.scss';

const TokenInfo = ({
  name,
  symbol,
  initialSupply,
  decimals,
  newTokenAddress,
  transactionHash,
  widgetCode,
  chainId,
}) => {
  const navigate = useNavigate();
  const { provider: contextProvider, signer, address, connected } = useWeb3();
  const [providerInstance, setProviderInstance] = useState(contextProvider || null);

  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  const [metaDescription, setMetaDescription] = useState('');
  const [metaImage, setMetaImage] = useState('');
  const [metaWebsite, setMetaWebsite] = useState('');
  const [metaTwitter, setMetaTwitter] = useState('');
  const [customizing, setCustomizing] = useState(false);
  const [customizeError, setCustomizeError] = useState('');
  const [customizeSuccess, setCustomizeSuccess] = useState(false);

  const [fetchedMetadata, setFetchedMetadata] = useState(null);
  const [fetchingMetadata, setFetchingMetadata] = useState(false);
  const [fetchMetadataError, setFetchMetadataError] = useState('');

  const [tokenContract, setTokenContract] = useState(null);

  const [previousImages, setPreviousImages] = useState([]);

  const [keywords, setKeywords] = useState('');
  const [generatedImages, setGeneratedImages] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationError, setGenerationError] = useState('');

  const [showPrevImageModal, setShowPrevImageModal] = useState(false);
  const [selectedPrevImage, setSelectedPrevImage] = useState('');

  const [agreedToTOS, setAgreedToTOS] = useState(false);
  const [showPreviousImages, setShowPreviousImages] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);

  console.log("TokenInfo render: connected =", connected, "signer =", !!signer, "tokenContract =", !!tokenContract);

  useEffect(() => {
    if (!contextProvider && window.ethereum) {
      const ethersProvider = new ethers.BrowserProvider(window.ethereum);
      setProviderInstance(ethersProvider);
    }
  }, [contextProvider]);

  // Initialize contract once conditions are met
  useEffect(() => {
    if (connected && signer && providerInstance && newTokenAddress) {
      console.log("Attempting to initialize contract...");
      try {
        const contract = new Contract(newTokenAddress, TokenABI, signer);
        setTokenContract(contract);
        console.log("Token contract initialized successfully.");
      } catch (error) {
        console.error("Error initializing Token Contract:", error);
      }
    } else {
      console.log("Waiting for connected:", connected, "signer:", !!signer, "provider:", !!providerInstance, "address:", newTokenAddress);
    }
  }, [connected, signer, providerInstance, newTokenAddress]);

  const fetchMetadata = async () => {
    if (!connected || !tokenContract) {
      console.log("Cannot fetch metadata: connected =", connected, "tokenContract =", !!tokenContract);
      return false;
    }
    console.log("Attempting to fetch metadata...");
    try {
      setFetchingMetadata(true);
      const metaUrl = await tokenContract.metaUrl();
      console.log("metaUrl fetched:", metaUrl);
      const response = await fetch(metaUrl, { cache: 'no-cache' });
      if (!response.ok) {
        throw new Error(`Failed to fetch metadata from ${metaUrl}, status ${response.status}`);
      }
      const data = await response.json();
      setFetchedMetadata(data);
      console.log("Metadata fetched successfully:", data);
      setFetchMetadataError('');
      return true;
    } catch (error) {
      console.error("Error fetching metadata:", error);
      setFetchMetadataError("Failed to fetch metadata.");
      return false;
    } finally {
      setFetchingMetadata(false);
    }
  };

  // Interval-based retry for metadata once tokenContract is ready
  useEffect(() => {
    if (!connected || !tokenContract) {
      console.log("Not ready to fetch metadata. connected =", connected, "tokenContract =", !!tokenContract);
      return;
    }

    // If metadata already fetched, no need to start interval
    if (fetchedMetadata) {
      console.log("Metadata already fetched, no interval needed.");
      return;
    }

    let attempts = 0;
    const maxAttempts = 10;
    console.log("Starting interval to fetch metadata...");
    const interval = setInterval(async () => {
      if (fetchedMetadata) {
        console.log("Metadata loaded during interval. Clearing interval now.");
        clearInterval(interval);
        return;
      }
      console.log("Metadata attempt:", attempts + 1);
      const success = await fetchMetadata();
      if (success || attempts >= maxAttempts) {
        console.log("Stopping metadata attempts. success =", success, "attempts =", attempts);
        clearInterval(interval);
      } else {
        attempts++;
      }
    }, 1000); // retry every 1 second

    return () => clearInterval(interval);
  }, [connected, tokenContract, customizeSuccess, fetchedMetadata]);

  useEffect(() => {
    const fetchPreviousImages = async () => {
      if (!newTokenAddress) return;
      try {
        const response = await fetch(`https://testnet.turtleblunt.com/api/images/${newTokenAddress}`, { cache: 'no-cache' });
        const data = await response.json();
        if (data.success && Array.isArray(data.images)) {
          setPreviousImages(data.images);
        }
      } catch (error) {
        console.error("Error fetching previous images:", error);
      }
    };
    fetchPreviousImages();
  }, [newTokenAddress]);

  const handleInteract = () => {
    navigate(`/interactor/${newTokenAddress}`);
  };

  const handleAddToken = async () => {
    if (!window.ethereum) {
      alert("MetaMask is not installed.");
      return;
    }

    const tokenData = {
      type: 'ERC20',
      options: {
        address: newTokenAddress,
        symbol: symbol,
        decimals: decimals,
        image: fetchedMetadata?.image || '',
      },
    };

    try {
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: tokenData,
      });

      if (wasAdded) {
        toast.success(`Token ${symbol} added to your wallet!`, {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        toast.info(`Token ${symbol} was not added.`, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error('Error adding token to wallet:', error);
      toast.error(`Failed to add token: ${error.message}`, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  const handleOpenCustomizeModal = () => {
    setMetaDescription(fetchedMetadata?.description || '');
    setMetaImage(fetchedMetadata?.image || '');
    setMetaWebsite(fetchedMetadata?.website || '');
    setMetaTwitter(fetchedMetadata?.twitter || '');
    setCustomizeError('');
    setCustomizeSuccess(false);
    setShowCustomizeModal(true);
  };

  const handleCloseCustomizeModal = () => {
    setShowCustomizeModal(false);
  };

  const handleCustomizeSubmit = async (e) => {
    e.preventDefault();
    setCustomizeError('');
    setCustomizeSuccess(false);
    setCustomizing(true);

    try {
      const metadataPayload = {
        name,
        description: metaDescription,
        image: metaImage,
        ...(metaWebsite && { website: metaWebsite }),
        ...(metaTwitter && { twitter: metaTwitter }),
      };

      const response = await fetch('https://testnet.turtleblunt.com/api/metadata', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(metadataPayload),
        cache: 'no-cache'
      });

      const data = await response.json();

      if (!response.ok || !data.success) {
        throw new Error(data.error || 'Failed to create metadata.');
      }

      const ipfsUrl = data.ipfsUrl;
      if (!ipfsUrl) {
        throw new Error('No IPFS URL returned.');
      }

      if (!tokenContract) {
        throw new Error('Token contract not initialized.');
      }

      const tx = await tokenContract.updateMetaUrl(ipfsUrl);
      await tx.wait();

      setCustomizeSuccess(true);
      setShowCustomizeModal(false);
      toast.success('Metadata updated successfully!', {
        position: "top-right",
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error customizing metadata:", error);
      setCustomizeError(`Error: ${error.message}`);
      toast.error(`Error: ${error.message}`, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setCustomizing(false);
    }
  };

  const handlePrevImageClick = (url) => {
    setSelectedPrevImage(url);
    setShowPrevImageModal(true);
  };

  const handleKeywordsChange = (e) => {
    const input = e.target.value;
    const words = input.trim().split(/\s+/);
    if (words.length <= 10) {
      setKeywords(input);
      setGenerationError('');
    } else {
      setGenerationError('Maximum of 10 words allowed.');
    }
  };

  const generateImages = async () => {
    if (!agreedToTOS) {
      toast.error('You must agree to the Terms of Service before generating images.', {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }

    if (keywords.trim() === '') {
      setGenerationError('Please enter keywords.');
      return;
    }

    if (keywords.trim().split(/\s+/).length > 10) {
      setGenerationError('No more than 10 words allowed.');
      return;
    }

    setIsGenerating(true);
    setGenerationError('');
    setGeneratedImages([]);
    setSelectedPrevImage('');

    try {
      const response = await fetch('https://testnet.turtleblunt.com/api/generate-image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          prompt: keywords,
          walletAddress: address,
          contractAddress: newTokenAddress
        }),
        cache: 'no-cache'
      });

      const data = await response.json();

      if (response.ok && data.success && data.ipfsLink) {
        setGeneratedImages([data.ipfsLink]);
        toast.success('Images generated successfully!', {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        throw new Error(data.error || 'Failed to generate images.');
      }
    } catch (err) {
      console.error('Error generating images:', err);
      setGenerationError(err.message || 'Failed to generate images.');
      toast.error(`Error: ${err.message}`, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSelectGeneratedImage = () => {
    setMetaImage(selectedPrevImage);
    setShowPrevImageModal(false);

    setMetaDescription(fetchedMetadata?.description || '');
    setMetaWebsite(fetchedMetadata?.website || '');
    setMetaTwitter(fetchedMetadata?.twitter || '');
    setCustomizeError('');
    setCustomizeSuccess(false);
    setShowCustomizeModal(true);
  };

  return (
    <div className={styles['token-info-container']}>

      <h2>{name} ({symbol})</h2>

      <div style={{marginBottom:'1.5rem'}}>
        <Button 
          variant="outline-primary" 
          onClick={() => setShowInstructions(!showInstructions)}
          aria-label="Toggle Instructions"
        >
          {showInstructions ? 'Hide Instructions' : 'Show Instructions'}
        </Button>
      </div>

      {showInstructions && (
        <div className="instructions-card">
          <h5>Next Steps & Instructions</h5>
          <p><strong>Interact with Your Token:</strong> Click "Interact with Your Token".</p>
          <p><strong>Generate an Image:</strong> In "Generate an Image," agree to the Terms of Service, enter keywords, generate an image, then update your token’s metadata.</p>
          <p><strong>Update Metadata:</strong> Use "Customize Token" to modify details.</p>
          <p><strong>Previously Generated Images:</strong> Toggle to see past images.</p>
          <p>Enjoy customizing and managing your token!</p>
        </div>
      )}

      <div className="info-card">
        <div className="details-row">
          <div className="detail-card">
            <div className="detail-icon"><FaCube /></div>
            <div className="detail-title">Name</div>
            <div className="detail-value">{name}</div>
          </div>
          <div className="detail-card">
            <div className="detail-icon"><FaCoins /></div>
            <div className="detail-title">Symbol</div>
            <div className="detail-value">{symbol}</div>
          </div>
          <div className="detail-card">
            <div className="detail-icon"><FaListOl /></div>
            <div className="detail-title">Initial Supply</div>
            <div className="detail-value">{initialSupply}</div>
          </div>
          <div className="detail-card">
            <div className="detail-icon"><FaSortNumericUp /></div>
            <div className="detail-title">Decimals</div>
            <div className="detail-value">{decimals}</div>
          </div>
        </div>
      </div>

      {transactionHash && (
        <div style={{ marginTop: '2rem', textAlign: 'center' }}>
          <strong>🔗 Transaction Hash:</strong>{" "}
          <a
            href={`${process.env.REACT_APP_EXPLORER_URL || "https://sepolia.basescan.org/"}tx/${transactionHash}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-success"
            aria-label={`View transaction hash for ${name}`}
          >
            {transactionHash}
          </a>{" "}
          <Button
            variant="outline-success"
            size="sm"
            onClick={() => copyToClipboard(transactionHash)}
            title="Copy Transaction Hash"
            aria-label={`Copy transaction hash of ${name}`}
            className="ms-2"
          >
            <FaCopy /> Copy
          </Button>
        </div>
      )}

      {widgetCode && (
        <div style={{ marginTop: '2rem' }}>
          <div className="section-heading">Widget Code</div>
          <InputGroup className="mt-2">
            <FormControl
              as="textarea"
              readOnly
              value={widgetCode}
              aria-label="Widget Code"
              className="bg-secondary text-light border-secondary"
              rows={3}
            />
            <Button
              variant="outline-success"
              onClick={() => copyToClipboard(widgetCode)}
              title="Copy Widget Code"
              aria-label="Copy Widget Code"
            >
              <FaCopy />
            </Button>
          </InputGroup>
          <p className="text-muted mt-2" style={{fontSize: '0.9rem'}}>
            Copy and embed on your website to add a buy tokens widget.
          </p>
        </div>
      )}

      <div className="section-heading">Current Metadata</div>
      <div className="metadata-section">
        {fetchingMetadata ? (
          <Spinner animation="border" variant="light" aria-label="Loading metadata" />
        ) : fetchedMetadata ? (
          <>
            {fetchedMetadata.image && (
              <img 
                src={fetchedMetadata.image} 
                alt={`${name} Logo`} 
                style={{ width: '100%', maxWidth: '300px', height: 'auto', display:'block', margin:'0 auto' }}
              />
            )}
            <p>{fetchedMetadata.description || 'No description available.'}</p>
          </>
        ) : (
          <p className="no-metadata">No metadata available.</p>
        )}
      </div>
      {fetchMetadataError && (
        <Alert variant="danger" className="mt-3" aria-live="assertive">{fetchMetadataError}</Alert>
      )}

      <div className="actions">
        <Button 
          variant="primary" 
          onClick={handleOpenCustomizeModal} 
          disabled={!tokenContract}
          aria-label="Customize Token Metadata"
        >
          Customize Token
        </Button>
        <Button 
          variant="secondary" 
          onClick={handleAddToken}
          aria-label={`Add ${symbol} to Wallet`}
        >
          Add {symbol} to Wallet
        </Button>
        <Button 
          variant="outline-primary" 
          onClick={handleInteract}
          aria-label="Interact with Your Token"
        >
          Interact with Your Token
        </Button>
      </div>

      <div className="image-generation-section">
        <h5>Generate an Image</h5>

        <div className="tos-wrapper">
          <p><strong>Terms of Service for Image Generation:</strong></p>
          <p>By generating images, you agree to adhere to <a href="https://openai.com/policies/usage-policies/" target="_blank" rel="noopener noreferrer">OpenAI's usage policies</a>. Images should not contain disallowed content.</p>
          <p>You are responsible for the images used for your token. If images violate policies, they may be removed.</p>
          <div className="agree-checkbox">
            <input
              type="checkbox"
              checked={agreedToTOS}
              onChange={() => setAgreedToTOS(!agreedToTOS)}
              aria-label="Agree to Terms of Service for Image Generation"
            />
            <label>
              I have read and agree to the Terms of Service for image generation.
            </label>
          </div>
        </div>

        <Form className="mt-3">
          <Form.Group controlId="keywords" className="form-group">
            <Form.Label>Keywords (Max 10 words):</Form.Label>
            <Form.Control
              type="text"
              placeholder="e.g. futuristic skyline"
              value={keywords}
              onChange={handleKeywordsChange}
              aria-label="Image Generation Keywords"
            />
            <Form.Text className="form-text">
              {keywords.trim() === ''
                ? 'Enter up to 10 descriptive words.'
                : `${10 - keywords.trim().split(/\s+/).length} words remaining`}
            </Form.Text>
            {generationError && <p className="text-danger mt-1">{generationError}</p>}
          </Form.Group>
          <Button
            variant="success"
            onClick={generateImages}
            disabled={!agreedToTOS || isGenerating || keywords.trim().split(/\s+/).length === 0 || keywords.trim().split(/\s+/).length > 10}
            type="button"
            className="generate-btn"
            aria-label="Generate Images"
          >
            {isGenerating ? <Spinner animation="border" size="sm" /> : 'Generate Images'}
          </Button>
        </Form>

        {generatedImages.length > 0 && (
          <div className="newly-generated-images">
            <h5>Newly Generated Images:</h5>
            <div className="images-grid">
              {generatedImages.map((url, index) => (
                <div
                  key={index}
                  className="image-thumb"
                  onClick={() => handlePrevImageClick(url)}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') handlePrevImageClick(url);
                  }}
                  aria-label={`View generated image ${index + 1}`}
                >
                  <img
                    src={url}
                    alt={`Generated ${index + 1}`}
                    style={{ width: '100%', maxWidth: '300px', height: 'auto', display:'block', margin:'0 auto' }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div style={{ marginTop: '2rem' }}>
        <Button 
          variant="outline-secondary" 
          onClick={() => setShowPreviousImages(!showPreviousImages)}
          aria-label="Toggle Previously Generated Images"
        >
          {showPreviousImages ? 'Hide Previously Generated Images' : 'Show Previously Generated Images'}
        </Button>
      </div>

      {showPreviousImages && previousImages && previousImages.length > 0 && (
        <>
          <div className="section-heading">Previously Generated Images</div>
          {previousImages.map((entry, idx) => (
            <div key={idx} style={{ marginBottom: '2rem' }} className="images-section">
              <p className="text-center" style={{fontWeight: '500'}}><strong>Prompt:</strong> {entry.prompt}</p>
              <div className="images-grid">
                {entry.links.map((link, i) => (
                  <div
                    key={i}
                    className="image-thumb"
                    onClick={() => handlePrevImageClick(link)}
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') handlePrevImageClick(link);
                    }}
                    aria-label={`View previously generated image ${idx + 1}-${i + 1}`}
                  >
                    <img 
                      src={link} 
                      alt={`Previous ${idx + 1}-${i + 1}`}
                      style={{ width: '100%', maxWidth: '300px', height: 'auto', display:'block', margin:'0 auto' }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </>
      )}

      <Modal show={showCustomizeModal} onHide={handleCloseCustomizeModal} centered>
        <Modal.Header closeButton className="bg-dark text-light">
          <Modal.Title>Customize Your Token Metadata</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-light">
          {customizeError && <Alert variant="danger">{customizeError}</Alert>}
          {customizeSuccess && <Alert variant="success">Metadata updated successfully!</Alert>}
          <Form onSubmit={handleCustomizeSubmit}>
            <Form.Group className="mb-3" controlId="metaDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter token description"
                value={metaDescription}
                onChange={(e) => setMetaDescription(e.target.value)}
                required
                aria-label="Token Description"
                className="bg-dark text-light border-secondary"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="metaImage">
              <Form.Label>Image URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="https://example.com/image.png"
                value={metaImage}
                onChange={(e) => setMetaImage(e.target.value)}
                required
                aria-label="Token Image URL"
                className="bg-dark text-light border-secondary"
              />
              <Form.Text className="text-muted">
                Provide a valid URL to an image for your token.
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="metaWebsite">
              <Form.Label>Website URL (Optional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="https://yourwebsite.com"
                value={metaWebsite}
                onChange={(e) => setMetaWebsite(e.target.value)}
                aria-label="Token Website URL"
                className="bg-dark text-light border-secondary"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="metaTwitter">
              <Form.Label>Twitter URL (Optional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="https://twitter.com/yourprofile"
                value={metaTwitter}
                onChange={(e) => setMetaTwitter(e.target.value)}
                aria-label="Token Twitter URL"
                className="bg-dark text-light border-secondary"
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button variant="secondary" onClick={handleCloseCustomizeModal} className="me-2" disabled={customizing}>
                Cancel
              </Button>
              <Button variant="primary" type="submit" disabled={customizing} aria-label="Update Token Metadata">
                {customizing ? <Spinner animation="border" size="sm" /> : 'Update Metadata'}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showPrevImageModal}
        onHide={() => setShowPrevImageModal(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton className="bg-dark text-light">
          <Modal.Title>Full-Size Image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center bg-dark text-light">
          {selectedPrevImage && (
            <img
              src={selectedPrevImage}
              alt="Selected Full Image"
              style={{ width:'100%', maxWidth:'300px', height:'auto', display:'block', margin:'0 auto' }}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="bg-dark">
          <Button
            variant="primary"
            onClick={handleSelectGeneratedImage}
            aria-label="Use this Image for Token"
          >
            Use this Image
          </Button>
          <Button variant="secondary" onClick={() => setShowPrevImageModal(false)} aria-label="Close Image Modal">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TokenInfo;
