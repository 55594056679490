// src/App.js

import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import NetworkBar from './components/NetworkBar';
import BaseNetworkInfoBar from './components/BaseNetworkInfoBar';
import BrandInfoBar from './components/BrandInfoBar';
import TestnetInstructionsBar from './components/TestnetInstructionsBar';
import CreateToken from './components/CreateToken'; // Assuming CreateToken is a page
import TokenSalePage from './components/TokenSalePage';
import ListingPage from './components/ListingPage';
import InteractorPage from './components/InteractorPage';
import FAQ from './pages/FAQ';
import WhitePaper from './pages/WhitePaper';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import Disclaimer from './pages/Disclaimer';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import { Container, Row, Col } from 'react-bootstrap';
import ErrorBoundary from './components/ErrorBoundary';
import './App.scss';

const App = () => {
  const location = useLocation();
  const isCreateTokenPage = location.pathname === '/';

  return (
    <ErrorBoundary>
      <NetworkBar />

      {/* Conditionally render the banners only on the Create Token page */}
      {isCreateTokenPage && (
        <>
          <BrandInfoBar />
          <BaseNetworkInfoBar />
        </>
      )}

      <TestnetInstructionsBar />

      {/* Main Content */}
      <div id="main-content">
        <Routes>
          <Route
            path="/"
            element={
              <CreateToken />
            }
          />
          <Route path="/token-sale/:tokenAddress" element={<TokenSalePage />} />
          <Route path="/interactor" element={<InteractorPage />} />
          <Route path="/interactor/:tokenAddress" element={<InteractorPage />} />
          <Route path="/listings" element={<ListingPage />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/whitepaper" element={<WhitePaper />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
        </Routes>
      </div>

      <Footer />

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </ErrorBoundary>
  );
};

export default App;
