// src/components/ListingPage/ListingPage.js

import React, { useEffect, useState, useMemo, useContext, useCallback } from 'react';
import {
  Container,
  Row,
  Col,
  Spinner,
  Button,
  OverlayTrigger,
  Tooltip,
  Form,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TokenCard from './TokenCard'; // Import the new TokenCard component
import { toast } from 'react-toastify';
import { Web3Context } from '../Web3Context'; // Import Web3Context
import TokenABI from '../abis/TokenABI.json'; // Import your Token ABI

import { Contract, isAddress, JsonRpcProvider } from 'ethers'; // Correct imports for ethers v6
import { formatUnits } from '@ethersproject/units'; // Correct import for formatUnits in v6

import styles from './ListingPage.module.scss'; // Import the SCSS module
import { formatNumber, formatDate } from '../utils/format'; // Import utility functions

const ListingPage = () => {
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(true);

  // State for search and sorting
  const [searchQuery, setSearchQuery] = useState('');
  const [sortCriteria, setSortCriteria] = useState('name-asc');
  // Possible values: 'name-asc', 'name-desc', 'date-asc', 'date-desc'

  // Define the explorer base URL using environment variable
  const explorerBaseUrl = process.env.REACT_APP_EXPLORER_URL || 'https://sepolia.basescan.org/';

  // Access Web3Context
  const { provider, signer, connected } = useContext(Web3Context);

  // Define a default provider using environment variables
  const defaultProvider = new JsonRpcProvider('https://sepolia.base.org');

  // Function to construct explorer URL for a given address
  const getExplorerUrl = useCallback((address) => {
    if (!address) return '#';
    return `${explorerBaseUrl}address/${address}`;
  }, [explorerBaseUrl]);

  // Function to fetch token metadata from smart contract
  const fetchTokenMetadata = useCallback(async (tokenAddress) => {
    if (!isAddress(tokenAddress)) return null;

    try {
      // Choose signer if connected; else, use defaultProvider
      const contract = connected && signer
        ? new Contract(tokenAddress, TokenABI, signer)
        : new Contract(tokenAddress, TokenABI, defaultProvider);

      // Fetch metaUrl from the contract
      const metaUrl = await contract.metaUrl(); // Ensure your contract has a metaUrl() function

      if (!metaUrl) return null;

      const response = await fetch(metaUrl);
      if (!response.ok) throw new Error(`Failed to fetch metadata: ${response.statusText}`);

      const metadata = await response.json();
      return metadata.image || null; // Return the image URL
    } catch (error) {
      console.error(`Error fetching metadata for token ${tokenAddress}:`, error);
      return null; // Return null to trigger fallback image
    }
  }, [connected, signer, defaultProvider]);

  useEffect(() => {
    const fetchTokens = async () => {
      setLoading(true);
      try {
        // Fetch tokens from your backend API
        const response = await fetch(`https://testnet.turtleblunt.com/tokens`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        // Assuming each token has an 'address' field
        // Fetch metadata for all tokens concurrently
        const tokensWithImages = await Promise.all(
          data.map(async (token) => {
            // Check if image URL is cached in localStorage
            const cacheKey = `tokenImage-${token.address}`;
            const cachedImage = localStorage.getItem(cacheKey);
            const cacheTimestamp = localStorage.getItem(`${cacheKey}-timestamp`);

            if (cachedImage && cacheTimestamp && (Date.now() - parseInt(cacheTimestamp, 10) < 24 * 60 * 60 * 1000)) { // 24 hours cache
              return { ...token, imageUrl: cachedImage };
            }

            // Fetch image URL from smart contract
            const imageUrl = await fetchTokenMetadata(token.address);

            // Cache the image URL and timestamp if available
            if (imageUrl) {
              localStorage.setItem(cacheKey, imageUrl);
              localStorage.setItem(`${cacheKey}-timestamp`, Date.now().toString());
            }

            return { ...token, imageUrl: imageUrl || '/images/default-token.png' }; // Fallback to default image
          })
        );

        setTokens(tokensWithImages);
        // Cache tokens data with a timestamp
        localStorage.setItem('tokens', JSON.stringify(tokensWithImages));
        localStorage.setItem('tokens-timestamp', Date.now().toString());
      } catch (error) {
        console.error('Error fetching tokens:', error);
        toast.error('Failed to fetch tokens from backend.', {
          toastId: 'fetch-tokens-error',
        });

        // Attempt to retrieve tokens from cache
        const cachedTokens = localStorage.getItem('tokens');
        const tokensTimestamp = localStorage.getItem('tokens-timestamp');

        if (cachedTokens && tokensTimestamp && (Date.now() - parseInt(tokensTimestamp, 10) < 24 * 60 * 60 * 1000)) { // 24 hours cache
          setTokens(JSON.parse(cachedTokens));
          toast.info('Displaying cached token data.', {
            toastId: 'displaying-cached-tokens',
          });
        } else {
          setTokens([]);
          toast.error('No cached data available.', {
            toastId: 'no-cached-data',
          });
        }
      } finally {
        setLoading(false);
      }
    };

    // Fetch tokens regardless of wallet connection
    fetchTokens();
  }, [connected, provider, signer, fetchTokenMetadata]);

  const filteredAndSortedTokens = useMemo(() => {
    // Filter by search query (by name)
    let filtered = tokens.filter((token) =>
      token.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Sort based on sortCriteria
    if (sortCriteria.startsWith('name')) {
      filtered.sort((a, b) => {
        const comp = a.name.localeCompare(b.name);
        return sortCriteria === 'name-asc' ? comp : -comp;
      });
    } else if (sortCriteria.startsWith('date')) {
      // Assuming tokens have a 'creationTimestamp' field
      filtered.sort((a, b) => {
        const comp = a.creationTimestamp - b.creationTimestamp;
        return sortCriteria === 'date-asc' ? comp : -comp;
      });
    }

    return filtered;
  }, [tokens, searchQuery, sortCriteria]);

  return (
    <div className={styles.listingContainer}>
      {/* Overlay for better readability */}
      <div className={styles.overlay}></div>

      <Container className={styles.content}>
        {/* Header Section */}
        <div className={styles.header}>
          <img
            src="/images/tokenlistings.png"
            alt="Token Listings"
            className={styles.mainHeaderImage}
          />
          <h1>Token Listings</h1>
          <p>Explore and interact with various tokens on the blockchain.</p>
        </div>

        {/* Search and Sorting controls */}
        <div className={styles.controls}>
          <Form.Control
            type="text"
            placeholder="Search by name..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className={styles.formControl}
            aria-label="Search Tokens by Name"
          />
          <Form.Select
            value={sortCriteria}
            onChange={(e) => setSortCriteria(e.target.value)}
            className={styles.formSelect}
            aria-label="Sort Tokens"
          >
            <option value="name-asc">Sort by Name (A-Z)</option>
            <option value="name-desc">Sort by Name (Z-A)</option>
            <option value="date-asc">Sort by Creation Date (Oldest First)</option>
            <option value="date-desc">Sort by Creation Date (Newest First)</option>
          </Form.Select>
        </div>

        {loading && tokens.length === 0 ? (
          <div className="text-center">
            <Spinner animation="border" role="status" />
            <div>Loading tokens...</div>
          </div>
        ) : (
          <Row className="justify-content-center">
            {filteredAndSortedTokens.length > 0 ? (
              filteredAndSortedTokens.map((token) => (
                <Col
                  md={4}
                  sm={6}
                  xs={12}
                  key={token.address}
                  className="mb-4 d-flex align-items-stretch"
                >
                  <TokenCard
                    token={token}
                    getExplorerUrl={getExplorerUrl}
                  />
                </Col>
              ))
            ) : (
              <div className="text-center w-100">
                <p>No tokens found.</p>
              </div>
            )}
          </Row>
        )}
      </Container>
    </div>
  );
};

// Helper function to validate Ethereum addresses (if needed elsewhere)
const isAddressValid = (address) => {
  return isAddress(address);
};

export default ListingPage;
