// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer bg-secondary text-light text-center p-3">
      <div className="d-flex flex-wrap justify-content-center align-items-center">
        <Link to="/whitepaper" className="text-light mx-3" style={{ textDecoration: 'underline' }}>
          White Paper
        </Link>
        <Link to="/privacy-policy" className="text-light mx-3" style={{ textDecoration: 'underline' }}>
          Privacy Policy
        </Link>
        <Link to="/terms-of-use" className="text-light mx-3" style={{ textDecoration: 'underline' }}>
          Terms of Use
        </Link>
        <Link to="/disclaimer" className="text-light mx-3" style={{ textDecoration: 'underline' }}>
          Disclaimer
        </Link>
      </div>
      <p className="mt-2">© {new Date().getFullYear()} TurtleBlunt TokenCreator</p>
    </footer>
  );
};

export default Footer;
