// src/pages/WhitePaper.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './WhitePaper.scss'; // Ensure this file exists and matches your theme

const WhitePaper = () => {
  return (
    <Container className="whitepaper-container my-5">
      <Row className="justify-content-center">
        <Col md={10}>
          <Card className="bg-dark text-light p-4 border-0 shadow-lg">
            <Card.Body>
              <h1 className="text-center mb-4">TBLUNT Token White Paper</h1>
              <p><strong>Version:</strong> 1.0</p>
              <p><strong>Date:</strong> [Insert Date]</p>

              <h2 className="mt-5">Abstract</h2>
              <p>
                TBLUNT began as a meme coin concept but has evolved into a functional credit token integral to our token creation and management platform. This platform empowers users to easily create and manage their own ERC20 tokens through an intuitive web interface, using TBLUNT tokens as credits to access services. While TBLUNT does not aspire to intrinsic financial value, it serves as a convenient unit of account and access mechanism, driving the token creation and maintenance features on the site.
              </p>
              <p>
                In this document, we present the core principles, utility, and envisioned use cases of TBLUNT, and how it interacts with our token creation ecosystem. TBLUNT’s primary goal is not speculation, but seamless integration as a "credit" system within a broader tokenization platform.
              </p>

              <h2>Introduction</h2>
              <p><strong>Background:</strong> The initial idea for TBLUNT emerged from the explosive popularity of meme coins—tokens created for community engagement and humor. However, as our project evolved, we recognized a need for more tangible utility. TBLUNT now anchors itself as a credit token within our token creation web application, replacing the complexity of ERC20 creation and management with a simple credit-based approach.</p>
              <p><strong>Problem Statement:</strong> Creating and deploying ERC20 tokens often requires technical skill and careful configuration. This complexity prevents many entrepreneurs, creators, and communities from leveraging tokenization.</p>
              <p><strong>Our Solution:</strong> Our platform provides a user-friendly interface for ERC20 token creation and management. TBLUNT tokens serve as credits within this platform. By abstracting the complexity, we enable a broader audience to engage confidently with Web3 technologies.</p>

              <h2>TBLUNT’s Utility</h2>
              <p><strong>1. Token Creation Credits:</strong> TBLUNT acts as a credit system. Users spend TBLUNT to deploy new ERC20 tokens via our platform. This replaces conventional fees and code complexity with a simple, token-based model.</p>
              <p><strong>2. Management Tools:</strong> Beyond creation, TBLUNT may be used to access advanced token management tools: updating metadata, setting token parameters, and integrating ETH drip dispensers for testing or community rewards.</p>
              <p><strong>3. Platform Sustainability:</strong> The credit-based model ensures that as demand for token creation and management grows, the platform remains sustainable. Users needing more tokens or features must acquire TBLUNT, supporting ongoing development and maintenance.</p>
              <p><strong>No Intrinsic Value:</strong> TBLUNT is not marketed as an investment. Its value lies solely in the services it unlocks on our platform.</p>

              <h2>Technical Overview</h2>
              <p><strong>Core Contract:</strong> TBLUNT is an ERC20 token implementing EIP-2612 permit functionality. It uses a fixed supply allocated at deployment. A portion is given to the platform owner, and a fee portion is allocated to a fee collector.</p>
              <p><strong>Platform Integration:</strong> Users connect their wallets, purchase or otherwise acquire TBLUNT, and then spend it to create tokens. The platform’s frontend calculates required credits, and the user approves and spends TBLUNT to finalize creation. For ETH drip features or other advanced tools, TBLUNT similarly functions as the access key.</p>

              <h2>Governance and Future Developments</h2>
              <p>Currently, governance resides with the platform owner, who can set parameters. In the future, community input and decentralized governance mechanisms may be introduced if the project expands. Appending community-driven decision-making could guide token supply adjustments, feature deployments, or platform fees.</p>

              <h2>Token Economics</h2>
              <p><strong>Distribution and Supply:</strong> The total supply is fixed at deployment. Most tokens go to the owner for sale or distribution as credits. A smaller portion goes to a fee collector address for operational costs.</p>
              <p><strong>Pricing and Availability:</strong> Users initially acquire TBLUNT through token sales, airdrops, or secondary markets. As the platform grows, continuous demand for TBLUNT as credits underpins a utility-driven value model.</p>
              <p><strong>Market Perception:</strong> While TBLUNT can be traded on secondary markets, its purpose remains utility. Should the platform become widely used, TBLUNT may have perceived value as the "currency" of token creation credits.</p>

              <h2>Risks and Disclaimers</h2>
              <p><strong>No Investment Promise:</strong> TBLUNT does not guarantee any financial returns, dividends, or equity. It is a utility token providing access to platform functionalities.</p>
              <p><strong>Platform Reliance:</strong> The value proposition depends on our platform’s success and stability. If the platform ceases operations or loses adoption, TBLUNT’s utility diminishes.</p>
              <p><strong>Regulatory Uncertainty:</strong> Cryptocurrency regulations vary by region. Future legal changes could impact TBLUNT’s use or listing on exchanges.</p>

              <h2>Conclusion</h2>
              <p>TBLUNT transcends its meme coin origins, serving as a practical utility token that empowers users to create, manage, and maintain their own ERC20 tokens with minimal friction. By simplifying token deployment, TBLUNT removes technical barriers and fosters greater inclusion in the decentralized ecosystem. While it does not promise financial gain, it unlocks tangible services—helping shape a more accessible future for tokenized digital economies.</p>

              <h2>Contact & More Information</h2>
              <p><strong>Website:</strong> [Insert URL]</p>
              <p><strong>GitHub:</strong> [Insert URL]</p>
              <p><strong>Community:</strong> [Insert social media or community channels]</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default WhitePaper;
