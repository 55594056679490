// src/pages/FAQ.js

import React, { useState } from 'react';
import {
  Accordion,
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Alert,
  InputGroup,
} from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCode, FaCopy, FaExclamationTriangle, FaQuestionCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';

const FAQ = () => {
  const [showContractCode, setShowContractCode] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');

  const handleShowContractCode = () => setShowContractCode(true);
  const handleCloseContractCode = () => setShowContractCode(false);

  const contractCode = `
  // SPDX-License-Identifier: MIT
  pragma solidity ^0.8.20;

  // Import OpenZeppelin contracts
  import "@openzeppelin/contracts/token/ERC20/ERC20.sol";
  import "@openzeppelin/contracts/token/ERC20/extensions/ERC20Permit.sol";
  import "@openzeppelin/contracts/access/Ownable.sol";           // For ownership management
  import "@openzeppelin/contracts/security/ReentrancyGuard.sol"; // For reentrancy protection

  contract Token is ERC20, ERC20Permit, Ownable, ReentrancyGuard {
      uint256 public tokenPrice;
      string public imgUrl; // Variable to store the image URL

      // Hardcoded fee collector address (replace with actual address)
      address private constant FEE_COLLECTOR = 0xe3e07D2e74021c94ff12B8e81450735bf7b67e49;

      // Events
      event TokenPriceSet(uint256 indexed newPrice);
      event TokensPurchased(address indexed buyer, uint256 amount, uint256 totalCost);
      event Withdrawn(address indexed withdrawnBy, uint256 amount);
      event Burn(address indexed burner, uint256 value);
      event ImgUrlUpdated(string newImgUrl);

      uint8 private _customDecimals;

      /**
       * @dev Constructor for the Token contract.
       * @param initialOwner Address of the initial owner of the token.
       * @param name_ Name of the token.
       * @param symbol_ Symbol of the token.
       * @param initialSupply Initial total supply of the token.
       * @param decimals_ Number of decimals the token uses.
       * @param initialPrice Initial price of the token in wei.
       */
      constructor(
          address initialOwner,
          string memory name_,
          string memory symbol_,
          uint256 initialSupply,
          uint8 decimals_,
          uint256 initialPrice
      )
          ERC20(name_, symbol_)
          ERC20Permit(name_)
          Ownable(initialOwner)
      {
          _customDecimals = decimals_;
          tokenPrice = initialPrice;

          // Set default imgUrl
          imgUrl = "http://turtleblunt.com/img.png";

          // Mint 90% of the initial supply to the owner
          uint256 ownerAmount = (initialSupply * 90) / 100;
          _mint(initialOwner, ownerAmount * 10 ** uint256(decimals_));

          // Mint 10% of the initial supply to the fee collector
          uint256 feeAmount = initialSupply - ownerAmount;
          _mint(FEE_COLLECTOR, feeAmount * 10 ** uint256(decimals_));
      }

      /**
       * @dev Override the decimals function to return the custom decimals.
       */
      function decimals() public view virtual override returns (uint8) {
          return _customDecimals;
      }

      /**
       * @dev Allows the owner to set a new token price.
       * @param newPrice The new price of the token in wei.
       */
      function setTokenPrice(uint256 newPrice) external onlyOwner {
          require(newPrice != tokenPrice, "New price must be different");
          tokenPrice = newPrice;
          emit TokenPriceSet(newPrice);
      }

      /**
       * @dev Allows users to buy tokens by sending ETH.
       */
      function buyTokens() external payable nonReentrant {
          require(msg.value > 0, "No ETH sent");

          uint256 tokenAmount = (msg.value * 10 ** uint256(_customDecimals)) / tokenPrice;
          require(balanceOf(owner()) >= tokenAmount, "Not enough tokens available for sale");

          _transfer(owner(), msg.sender, tokenAmount);

          emit TokensPurchased(msg.sender, tokenAmount, msg.value);
      }

      /**
       * @dev Allows the owner to withdraw accumulated ETH from the contract.
       */
      function withdraw() external onlyOwner nonReentrant {
          uint256 balance = address(this).balance;
          require(balance > 0, "No ETH to withdraw");

          (bool success, ) = owner().call{value: balance}("");
          require(success, "Withdraw failed");
          emit Withdrawn(msg.sender, balance);
      }

      /**
       * @dev Allows token holders to burn their tokens, reducing the total supply.
       * @param amount The amount of tokens to burn (in smallest units, including decimals).
       */
      function burn(uint256 amount) external {
          require(amount > 0, "Burn amount must be greater than zero");
          require(balanceOf(msg.sender) >= amount, "Burn amount exceeds balance");
          _burn(msg.sender, amount);
          emit Burn(msg.sender, amount);
      }

      /**
       * @dev Allows the owner to update the image URL associated with the token.
       * @param newImgUrl The new image URL.
       */
      function updateImgUrl(string memory newImgUrl) external onlyOwner {
          require(bytes(newImgUrl).length > 0, "Image URL cannot be empty");
          imgUrl = newImgUrl;
          emit ImgUrlUpdated(newImgUrl);
      }

      /**
       * @dev Fallback function to accept ETH.
       */
      receive() external payable {}
  }
  `;

  // Utility function to truncate the transaction hash
  const truncateHash = (hash) => {
    if (!hash) return '';
    return `${hash.substring(0, 6)}...${hash.substring(hash.length - 4)}`;
  };

  return (
    <Container className="mt-5 pt-5">
      <Row className="justify-content-center">
        <Col lg={8} md={10} sm={12}>
          <h2 className="text-center mb-4 text-light">Frequently Asked Questions</h2>
          <Accordion defaultActiveKey="0" alwaysOpen>
            {/* General Questions */}
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> What is TurtleBlunt Token Creator?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                TurtleBlunt Token Creator is a decentralized application (dApp) built on the Ethereum blockchain that allows users to easily create, interact with, and manage custom ERC-20 tokens. Our platform leverages Web3 technologies and Ethers.js to provide a secure and intuitive experience for both novice and experienced blockchain enthusiasts.
              </Accordion.Body>
            </Accordion.Item>

            {/* Wallet Connection */}
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> How do I connect my wallet to TurtleBlunt Token Creator?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                To connect your wallet:
                <ol>
                  <li>Click the <strong>"Connect Wallet"</strong> button in the top navigation bar.</li>
                  <li>Select your wallet provider (e.g., MetaMask, Coinbase Wallet).</li>
                  <li>Approve the connection request in your wallet extension or mobile app.</li>
                  <li>Your wallet address will now be displayed, granting you access to all dApp features.</li>
                </ol>
              </Accordion.Body>
            </Accordion.Item>

            {/* Token Creation */}
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> How do I create a new token?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                To create a new token:
                <ol>
                  <li>Ensure your wallet is connected and you have sufficient ETH and TBLUNT tokens.</li>
                  <li>Navigate to the <strong>"Create Token"</strong> page.</li>
                  <li>Fill in the required details:
                    <ul>
                      <li><strong>Token Name:</strong> The name of your token.</li>
                      <li><strong>Token Symbol:</strong> The symbol representing your token (e.g., TBL).</li>
                      <li><strong>Initial Supply:</strong> The total supply of tokens to be minted.</li>
                      <li><strong>Decimals:</strong> The number of decimal places your token uses (commonly 18).</li>
                      <li><strong>Initial Price:</strong> The initial price of your token in ETH.</li>
                    </ul>
                  </li>
                  <li>Review the details and confirm the creation.</li>
                  <li>Approve the transaction in your wallet.</li>
                  <li>Your new token will be created and added to the listings page.</li>
                </ol>
                <p><strong>Note:</strong> A 3% fee in TBLUNT tokens applies to token creation, supporting platform development and maintenance.</p>
              </Accordion.Body>
            </Accordion.Item>

            {/* 3% Fee Explanation */}
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> Why is there a 3% fee when creating a token?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                The 3% fee in TBLUNT tokens when creating a token is implemented to support the ongoing development and sustainability of TurtleBlunt Token Creator. This fee helps cover operational costs, fund new features, and ensure the platform remains free of ads and accessible to all users.
              </Accordion.Body>
            </Accordion.Item>

            {/* Using the Interactor Page */}
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> How do I use the Interactor page to manage my tokens?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                The Interactor page allows you to manage your tokens directly. To use it:
                <ol>
                  <li>Navigate to the <strong>"Interactor"</strong> page from the main menu.</li>
                  <li>Enter the contract address of your token or select it from your recently created tokens.</li>
                  <li>Click <strong>"Connect"</strong> to load the token details.</li>
                  <li>You can now perform various actions:
                    <ul>
                      <li><strong>Set Token Price:</strong> Adjust the price of your token.</li>
                      <li><strong>Update Image URL:</strong> Change the image associated with your token's listing.</li>
                      <li><strong>Burn Tokens:</strong> Permanently remove tokens from circulation.</li>
                      <li><strong>Withdraw ETH:</strong> Withdraw ETH accumulated from token sales (owner only).</li>
                    </ul>
                  </li>
                  <li>Execute the desired action and confirm the transaction in your wallet.</li>
                </ol>
              </Accordion.Body>
            </Accordion.Item>

            {/* Updating Image URLs */}
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> How can I update the image URL for my token's listing?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                To update the image URL for your token's listing:
                <ol>
                  <li>Go to the <strong>"Interactor"</strong> page.</li>
                  <li>Connect to your token by entering its contract address.</li>
                  <li>In the token details, find the <strong>"Update Image URL"</strong> section.</li>
                  <li>Enter the new image URL (ensure it's a valid URL pointing to an image file like .jpg or .png).</li>
                  <li>Click <strong>"Update Image URL"</strong> and confirm the transaction in your wallet.</li>
                  <li>The new image will reflect on the <strong>"Listings"</strong> page once the transaction is confirmed.</li>
                </ol>
                <p><strong>Tip:</strong> Use a reliable image hosting service to ensure your image loads correctly.</p>
              </Accordion.Body>
            </Accordion.Item>

            {/* Contract Source Code */}
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> Can I view the source code of the token contract?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                Yes, you can view the source code of the token contract. Click the button below to reveal the code:
                <div className="text-center mt-3">
                  <Button variant="outline-primary" onClick={handleShowContractCode}>
                    <FaCode className="me-2" /> View Contract Source Code
                  </Button>
                </div>
                <Modal show={showContractCode} onHide={handleCloseContractCode} size="lg" centered>
                  <Modal.Header closeButton className="bg-dark text-light">
                    <Modal.Title>Token Contract Source Code</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="bg-dark text-light">
                    <Form.Group controlId="contractCode">
                      <Form.Label>Smart Contract Code (Solidity)</Form.Label>
                      <Form.Control as="textarea" rows={20} readOnly value={contractCode} className="bg-dark text-light" />
                    </Form.Group>
                    <CopyToClipboard text={contractCode} onCopy={() => {
                      setCopySuccess('Copied!');
                      toast.success('Contract code copied to clipboard!', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }}>
                      <Button variant="success" className="mt-3">
                        <FaCopy className="me-2" /> Copy to Clipboard
                      </Button>
                    </CopyToClipboard>
                    {copySuccess && <span className="text-success ms-3">{copySuccess}</span>}
                  </Modal.Body>
                </Modal>
              </Accordion.Body>
            </Accordion.Item>

            {/* Contract Explanation */}
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> How does the token contract work?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                The token contract is an ERC-20 compliant smart contract with additional features:
                <ul>
                  <li><strong>Token Creation:</strong> Upon deployment, the contract mints 90% of the total supply to the creator and 10% to a fee collector address.</li>
                  <li><strong>Custom Decimals:</strong> You can specify the number of decimals your token uses.</li>
                  <li><strong>Token Price Management:</strong> The owner can set the token price, allowing users to purchase tokens by sending ETH.</li>
                  <li><strong>Buying Tokens:</strong> Users can buy tokens by sending ETH to the contract. The contract calculates the amount of tokens based on the current token price.</li>
                  <li><strong>Withdrawals:</strong> The owner can withdraw accumulated ETH from token sales.</li>
                  <li><strong>Burn Functionality:</strong> Token holders can burn their tokens to reduce the total supply.</li>
                  <li><strong>Image URL Update:</strong> The owner can update the image URL associated with the token for display on listings.</li>
                  <li><strong>Security Measures:</strong> The contract uses OpenZeppelin libraries for standard implementation and includes reentrancy guards.</li>
                </ul>
                <p>This design ensures flexibility and security, allowing token creators to manage their tokens effectively.</p>
              </Accordion.Body>
            </Accordion.Item>

            {/* Troubleshooting */}
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                <FaExclamationTriangle className="me-2 text-warning" /> I'm experiencing issues while using TurtleBlunt Token Creator. How can I troubleshoot?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                Here are some common troubleshooting steps:
                <ul>
                  <li><strong>Wallet Connection Issues:</strong> Ensure your wallet extension is installed and updated. Try reconnecting or using a different browser.</li>
                  <li><strong>Network Switching Errors:</strong> Verify that you are connected to the correct network (e.g., Ethereum Mainnet or Testnet). If the error persists, try adding the network manually.</li>
                  <li><strong>Transaction Failures:</strong> Check if you have sufficient funds for gas fees. Ensure that the contract address and parameters are correct.</li>
                  <li><strong>Image Not Displaying:</strong> Make sure the image URL is valid and accessible. Use supported image formats like JPEG or PNG.</li>
                  <li><strong>Unexpected Page Reloads:</strong> Remove any browser extensions that might interfere with the dApp. Clear your browser cache and cookies.</li>
                  <li><strong>Persistent Errors:</strong> Contact our support team at <a href="mailto:support@turtleblunt.com" className="text-info">support@turtleblunt.com</a> with detailed information about the issue.</li>
                </ul>
                <Alert variant="warning" className="mt-3">
                  <strong>Tip:</strong> Always ensure you're interacting with the official TurtleBlunt Token Creator website to avoid phishing attempts.
                </Alert>
              </Accordion.Body>
            </Accordion.Item>

            {/* Security */}
            <Accordion.Item eventKey="9">
              <Accordion.Header>
                <FaExclamationTriangle className="me-2 text-warning" /> Is my wallet information safe with TurtleBlunt Token Creator?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                Yes, your wallet information is secure. TurtleBlunt Token Creator uses industry-standard security measures to ensure that your private keys and sensitive data remain confidential. We do not store or access your private keys at any point. Always ensure you're accessing the dApp through official and secure channels.
              </Accordion.Body>
            </Accordion.Item>

            {/* Adding Token to Wallet */}
            <Accordion.Item eventKey="10">
              <Accordion.Header>
                <FaExclamationTriangle className="me-2 text-warning" /> How do I add my newly created token to my wallet?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                To add your token to your wallet:
                <ol>
                  <li>Copy the contract address of your new token.</li>
                  <li>Open your wallet extension (e.g., MetaMask).</li>
                  <li>Navigate to the <strong>"Assets"</strong> tab.</li>
                  <li>Click on <strong>"Import Tokens"</strong>.</li>
                  <li>Paste the token contract address.</li>
                  <li>Your wallet should automatically detect the token symbol and decimals. If not, enter them manually.</li>
                  <li>Confirm to add the token to your wallet.</li>
                </ol>
                <p><strong>Note:</strong> Ensure that you're adding the correct contract address to avoid scams.</p>
              </Accordion.Body>
            </Accordion.Item>

            {/* Fee Collector Address */}
            <Accordion.Item eventKey="11">
              <Accordion.Header>
                <FaExclamationTriangle className="me-2 text-warning" /> What is the fee collector address used for?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                The fee collector address is a hardcoded address within the token contract that receives 10% of the initial token supply. This allocation supports the platform's development and maintenance, ensuring continuous improvement and sustainability of TurtleBlunt Token Creator.
              </Accordion.Body>
            </Accordion.Item>

            {/* Max Supply and Tokenomics */}
            <Accordion.Item eventKey="12">
              <Accordion.Header>
                <FaExclamationTriangle className="me-2 text-warning" /> Can I modify the total supply after the token is created?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                No, once the token is created, the total supply is fixed based on the initial supply you specified. However, token holders can reduce the circulating supply by burning tokens using the burn function on the Interactor page.
              </Accordion.Body>
            </Accordion.Item>

            {/* Additional Troubleshooting */}
            <Accordion.Item eventKey="13">
              <Accordion.Header>
                <FaExclamationTriangle className="me-2 text-warning" /> I'm facing issues with transactions not being confirmed. What should I do?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                If your transactions are not being confirmed:
                <ul>
                  <li><strong>Check Gas Fees:</strong> Ensure you have enough ETH to cover the gas fees. You can increase the gas limit or gas price to expedite the transaction.</li>
                  <li><strong>Network Congestion:</strong> Ethereum network congestion can delay transactions. Check the current network status and try again later.</li>
                  <li><strong>Contract Issues:</strong> Ensure that the contract address and methods you're interacting with are correct.</li>
                  <li><strong>Wallet Issues:</strong> Sometimes, restarting your wallet extension or browser can resolve connectivity issues.</li>
                  <li><strong>Retry the Transaction:</strong> If the transaction failed, you might need to resend it. Ensure that previous failed attempts are cleared.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* Contact Support */}
            <Accordion.Item eventKey="14">
              <Accordion.Header>
                <FaExclamationTriangle className="me-2 text-warning" /> How can I contact support for further assistance?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                If you've followed the troubleshooting steps and still face issues, feel free to reach out to our support team:
                <ul>
                  <li>Email: <a href="mailto:support@turtleblunt.com" className="text-info">support@turtleblunt.com</a></li>
                  <li>Discord: <a href="https://discord.gg/yourdiscord" target="_blank" rel="noopener noreferrer" className="text-info">Join our Discord Community</a></li>
                  <li>Twitter: <a href="https://twitter.com/yourtwitter" target="_blank" rel="noopener noreferrer" className="text-info">@yourtwitter</a></li>
                </ul>
                Please provide detailed information about your issue, including screenshots and transaction hashes if applicable, to help us assist you better.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default FAQ;
