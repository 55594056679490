// src/components/InteractorPage/InteractorPage.js

import React, { useEffect, useState, useContext } from 'react';
import { ethers, Contract, isAddress, parseUnits, formatUnits } from 'ethers';
import TokenABI from '../abis/TokenABI.json';
import { toast } from 'react-toastify';
import {
  Container,
  Form,
  Button,
  Table,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Modal,
  Row,
  Col,
} from 'react-bootstrap';
import { Web3Context } from '../Web3Context';
import { FaCopy } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import styles from './InteractorPage.module.scss';
import { copyToClipboard } from '../utils/clipboard';
import { formatNumber, formatDate } from '../utils/format'; // Import formatting utilities

const InteractorPage = () => {
  const { tokenAddress: urlTokenAddress } = useParams();
  const { provider, signer, address, connectWallet, connected, loading: walletLoading } = useContext(Web3Context);

  const [contractAddress, setContractAddress] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [contract, setContract] = useState(null);

  const [tokenName, setTokenName] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [decimals, setDecimals] = useState(null);
  const [totalSupply, setTotalSupply] = useState('');
  const [tokenPrice, setTokenPrice] = useState('');
  const [owner, setOwner] = useState('');
  const [metaUrl, setMetaUrl] = useState('');
  const [metadata, setMetadata] = useState(null);

  const [userBalance, setUserBalance] = useState('');
  const [ethBalance, setEthBalance] = useState('');
  const [contractEthBalance, setContractEthBalance] = useState('0');

  const [newPrice, setNewPrice] = useState('');
  const [burnAmount, setBurnAmount] = useState('');
  const [loading, setLoading] = useState(false);

  const explorerUrl = process.env.REACT_APP_EXPLORER_URL || 'https://basescan.org/';

  const [showMetadataModal, setShowMetadataModal] = useState(false);
  const [metadataName, setMetadataName] = useState('');
  const [metadataDescription, setMetadataDescription] = useState('');
  const [metadataImage, setMetadataImage] = useState('');
  const [metadataWebsite, setMetadataWebsite] = useState('');
  const [metadataTwitter, setMetadataTwitter] = useState('');

  // Image Generation State
  const [keywords, setKeywords] = useState('');
  const [generatedImages, setGeneratedImages] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationError, setGenerationError] = useState('');
  const [selectedImage, setSelectedImage] = useState('');

  // State for storing previously generated images
  const [previousImages, setPreviousImages] = useState([]);
  const [newMetaUrl, setNewMetaUrl] = useState('');

  // States for the previously generated image modal
  const [showPrevImageModal, setShowPrevImageModal] = useState(false);
  const [selectedPrevImage, setSelectedPrevImage] = useState('');

  const handlePrevImageClick = (url) => {
    setSelectedPrevImage(url);
    setShowPrevImageModal(true);
  };

  const handleAddressChange = (e) => {
    setContractAddress(e.target.value);
  };

  const getExplorerUrl = (txHashOrAddress) => {
    if (!txHashOrAddress) return '#';
    const isTx = txHashOrAddress.length === 66;
    return isTx
      ? `${explorerUrl}tx/${txHashOrAddress}`
      : `${explorerUrl}address/${txHashOrAddress}`;
  };

  const connectContract = async (e, isUserInitiated = true) => {
    if (e && e.preventDefault) e.preventDefault();

    if (!isAddress(contractAddress)) {
      if (isUserInitiated) {
        toast.error('Invalid contract address.', { toastId: 'invalid-contract-address' });
      }
      return;
    }

    if (!provider || !signer) {
      if (isUserInitiated) {
        toast.error('Please connect your wallet first.', { toastId: 'wallet-not-connected' });
      }
      return;
    }

    try {
      setLoading(true);
      const tempContract = new Contract(contractAddress, TokenABI, signer);

      const [name, symbol, dec, supply, price, contractOwner, currentMetaUrl] = await Promise.all([
        tempContract.name(),
        tempContract.symbol(),
        tempContract.decimals(),
        tempContract.totalSupply(),
        tempContract.tokenPrice(),
        tempContract.owner(),
        tempContract.metaUrl(),
      ]);

      let decNumber = null;
      if (typeof dec === 'number') {
        decNumber = dec;
      } else if (typeof dec === 'bigint') {
        decNumber = Number(dec);
      } else if (dec && typeof dec.toNumber === 'function') {
        decNumber = dec.toNumber();
      }

      setDecimals(decNumber);
      setTokenName(name);
      setTokenSymbol(symbol);
      setTotalSupply(decNumber !== null ? formatNumber(formatUnits(supply, decNumber)) : 'N/A');
      setTokenPrice(formatNumber(formatUnits(price, 'ether')));
      setOwner(contractOwner);
      setMetaUrl(currentMetaUrl);
      setContract(tempContract);
      setIsConnected(true);

      const balance = await tempContract.balanceOf(address);
      setUserBalance(decNumber !== null ? formatNumber(formatUnits(balance, decNumber)) : 'N/A');

      const ethBal = await provider.getBalance(address);
      setEthBalance(formatNumber(formatUnits(ethBal, 'ether')));

      const contractBalance = await provider.getBalance(contractAddress);
      setContractEthBalance(formatNumber(formatUnits(contractBalance, 'ether')));

      fetchMetadata(currentMetaUrl);
      await fetchPreviousImages(contractAddress);

      if (isUserInitiated) {
        toast.success('Connected to contract successfully!', { toastId: 'contract-connect-success' });
      }

      localStorage.setItem('contractAddress', contractAddress);
    } catch (err) {
      console.error('Error connecting to contract:', err);
      if (isUserInitiated) {
        toast.error('Failed to connect to contract.', { toastId: 'contract-connect-error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchMetadata = async (url) => {
    if (!url || url.trim() === '') {
      setMetadata(null);
      return;
    }

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch metadata: ${response.statusText}`);
      }
      const data = await response.json();
      setMetadata(data);
    } catch (err) {
      console.error('Error fetching metadata:', err);
      toast.error('Failed to fetch metadata.', { toastId: 'fetch-metadata-error' });
      setMetadata(null);
    }
  };

  const fetchPreviousImages = async (contractAddr) => {
    try {
      const response = await fetch(`https://testnet.turtleblunt.com/api/images/${contractAddr}`);
      const data = await response.json();
      if (data.success && Array.isArray(data.images)) {
        setPreviousImages(data.images);
      } else {
        setPreviousImages([]);
      }
    } catch (err) {
      console.error('Error fetching previous images:', err);
      setPreviousImages([]);
    }
  };

  const handleDisconnectContract = () => {
    setIsConnected(false);
    setContract(null);
    setContractAddress('');
    setTokenName('');
    setTokenSymbol('');
    setDecimals(null);
    setTotalSupply('');
    setTokenPrice('');
    setOwner('');
    setMetaUrl('');
    setUserBalance('');
    setEthBalance('');
    setContractEthBalance('0');
    setNewPrice('');
    setBurnAmount('');
    setNewMetaUrl('');
    setMetadata(null);
    setSelectedImage('');
    setKeywords('');
    setGeneratedImages([]);
    setGenerationError('');
    setPreviousImages([]);
    localStorage.removeItem('contractAddress');
    toast.info('Disconnected from contract.', { toastId: 'contract-disconnected' });
  };

  useEffect(() => {
    const fetchUserBalance = async () => {
      if (contract && address && decimals !== null) {
        try {
          const balance = await contract.balanceOf(address);
          setUserBalance(formatNumber(formatUnits(balance, decimals)));

          const ethBal = await provider.getBalance(address);
          setEthBalance(formatNumber(formatUnits(ethBal, 'ether')));
        } catch (err) {
          console.error('Error fetching user balance:', err);
          toast.error('Failed to fetch user balance.', { toastId: 'fetch-user-balance-error' });
        }
      }
    };
    fetchUserBalance();
  }, [contract, address, provider, decimals]);

  useEffect(() => {
    const fetchDetails = async () => {
      if (contract && decimals !== null) {
        try {
          const supply = await contract.totalSupply();
          setTotalSupply(formatNumber(formatUnits(supply, decimals)));

          const price = await contract.tokenPrice();
          setTokenPrice(formatNumber(formatUnits(price, 'ether')));

          const contractBalance = await provider.getBalance(contractAddress);
          setContractEthBalance(formatNumber(formatUnits(contractBalance, 'ether')));
        } catch (err) {
          console.error('Error fetching details:', err);
          toast.error('Failed to fetch contract details.', { toastId: 'fetch-contract-details-error' });
        }
      }
    };
    fetchDetails();
  }, [contract, contractAddress, provider, decimals]);

  useEffect(() => {
    const init = async () => {
      if (urlTokenAddress && isAddress(urlTokenAddress)) {
        setContractAddress(urlTokenAddress);
        await connectContract({ preventDefault: () => {} }, false);
      } else {
        const savedContractAddress = localStorage.getItem('contractAddress');
        if (savedContractAddress && isAddress(savedContractAddress)) {
          setContractAddress(savedContractAddress);
          await connectContract({ preventDefault: () => {} }, false);
        }
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlTokenAddress, connected, signer, provider]);

  const handleSetPrice = async (e) => {
    e.preventDefault();

    if (!newPrice || isNaN(newPrice) || Number(newPrice) <= 0) {
      toast.error('Please enter a valid new price.', { toastId: 'invalid-new-price' });
      return;
    }

    setLoading(true);
    try {
      const tx = await contract.setTokenPrice(parseUnits(newPrice, 'ether'));
      toast.info('Transaction submitted. Awaiting confirmation...', { toastId: 'set-price-tx-submitted' });
      const receiptTx = await tx.wait();
      toast.success('Token price updated successfully!', {
        toastId: 'set-price-success',
        onClick: () => window.open(getExplorerUrl(receiptTx.transactionHash), '_blank'),
      });

      const updatedPrice = await contract.tokenPrice();
      setTokenPrice(formatNumber(formatUnits(updatedPrice, 'ether')));
      setNewPrice('');

      const contractBalance = await provider.getBalance(contractAddress);
      setContractEthBalance(formatNumber(formatUnits(contractBalance, 'ether')));
    } catch (err) {
      console.error('Error setting token price:', err);
      if (err.code === 4001) {
        toast.error('Transaction rejected by user.', { toastId: 'set-price-tx-rejected' });
      } else {
        toast.error('Failed to set token price.', { toastId: 'set-price-tx-failed' });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBurn = async (e) => {
    e.preventDefault();

    if (!burnAmount || isNaN(burnAmount) || Number(burnAmount) <= 0) {
      toast.error('Please enter a valid amount to burn.', { toastId: 'invalid-burn-amount' });
      return;
    }

    if (Number(burnAmount) > Number(userBalance)) {
      toast.error('Burn amount exceeds your token balance.', { toastId: 'burn-amount-exceeds' });
      return;
    }

    setLoading(true);
    try {
      const tx = await contract.burn(parseUnits(burnAmount, decimals));
      toast.info('Transaction submitted. Awaiting confirmation...', { toastId: 'burn-tx-submitted' });
      const receiptTx = await tx.wait();
      toast.success('Tokens burned successfully!', {
        toastId: 'burn-success',
        onClick: () => window.open(getExplorerUrl(receiptTx.transactionHash), '_blank'),
      });

      const updatedBalance = await contract.balanceOf(address);
      setUserBalance(formatNumber(formatUnits(updatedBalance, decimals)));

      const updatedSupply = await contract.totalSupply();
      setTotalSupply(formatNumber(formatUnits(updatedSupply, decimals)));

      const contractBalance = await provider.getBalance(contractAddress);
      setContractEthBalance(formatNumber(formatUnits(contractBalance, 'ether')));
    } catch (err) {
      console.error('Error burning tokens:', err);
      if (err.code === 4001) {
        toast.error('Transaction rejected by user.', { toastId: 'burn-tx-rejected' });
      } else {
        toast.error('Failed to burn tokens.', { toastId: 'burn-tx-failed' });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleWithdraw = async () => {
    if (address.toLowerCase() !== owner.toLowerCase()) {
      toast.error('Only the contract owner can withdraw ETH.', { toastId: 'withdraw-not-owner' });
      return;
    }

    if (Number(contractEthBalance) <= 0) {
      toast.error('No ETH available to withdraw.', { toastId: 'withdraw-no-eth' });
      return;
    }

    setLoading(true);
    try {
      const tx = await contract.withdraw();
      toast.info('Transaction submitted. Awaiting confirmation...', { toastId: 'withdraw-tx-submitted' });
      const receiptTx = await tx.wait();
      toast.success('ETH withdrawn successfully!', {
        toastId: 'withdraw-success',
        onClick: () => window.open(getExplorerUrl(receiptTx.transactionHash), '_blank'),
      });

      const ethBal = await provider.getBalance(address);
      setEthBalance(formatNumber(formatUnits(ethBal, 'ether')));

      const contractBalance = await provider.getBalance(contractAddress);
      setContractEthBalance(formatNumber(formatUnits(contractBalance, 'ether')));
    } catch (err) {
      console.error('Error withdrawing ETH:', err);
      if (err.code === 4001) {
        toast.error('Transaction rejected by user.', { toastId: 'withdraw-tx-rejected' });
      } else {
        toast.error('Failed to withdraw ETH.', { toastId: 'withdraw-tx-failed' });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOpenMetadataModal = () => {
    setMetadataName('');
    setMetadataDescription('');
    setMetadataImage(selectedImage || '');
    setMetadataWebsite('');
    setMetadataTwitter('');
    setShowMetadataModal(true);
  };

  const handleCloseMetadataModal = () => {
    setShowMetadataModal(false);
  };

  const handleMetadataSubmit = async (e) => {
    e.preventDefault();

    if (!metadataName.trim() || !metadataDescription.trim()) {
      toast.error('Name and Description are required for metadata.', { toastId: 'metadata-validation-error' });
      return;
    }

    setLoading(true);
    try {
      // First upload metadata to IPFS
      const response = await fetch('https://testnet.turtleblunt.com/api/metadata', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: metadataName,
          description: metadataDescription,
          image: metadataImage,
          website: metadataWebsite,
          social: {
            twitter: metadataTwitter,
          },
        }),
      });

      const data = await response.json();

      if (!response.ok || !data.success) {
        throw new Error(data.error || 'Unknown error occurred.');
      }

      // Metadata successfully uploaded to IPFS
      const newIpfsUrl = data.ipfsUrl;
      toast.success('Metadata uploaded to IPFS successfully!', { toastId: 'metadata-upload-success' });

      // Now automatically update metaUrl on the contract
      toast.info('Updating metadata URL on the blockchain...', { toastId: 'update-metaurl-tx-submitted' });
      const tx = await contract.updateMetaUrl(newIpfsUrl);
      const receiptTx = await tx.wait();
      toast.success('Metadata URL updated on the contract!', {
        toastId: 'update-metaurl-success',
        onClick: () => window.open(getExplorerUrl(receiptTx.transactionHash), '_blank'),
      });

      // Update state and fetch new metadata
      setMetaUrl(newIpfsUrl);
      fetchMetadata(newIpfsUrl);

      handleCloseMetadataModal();
    } catch (err) {
      console.error('Error creating and updating metadata:', err);
      if (err.code === 4001) {
        toast.error('Transaction rejected by user.', { toastId: 'update-metaurl-tx-rejected' });
      } else {
        toast.error(`Failed to create/update metadata: ${err.message}`, { toastId: 'metadata-create-update-failed' });
      }
    } finally {
      setLoading(false);
    }
  };

  // Image Generation Handlers
  const handleKeywordsChange = (e) => {
    const input = e.target.value;
    const words = input.trim().split(/\s+/);
    if (words.length <= 10) {
      setKeywords(input);
      setGenerationError('');
    } else {
      setGenerationError('Maximum of 10 words allowed.');
    }
  };

  const generateImages = async () => {
    if (keywords.trim() === '') {
      setGenerationError('Please enter keywords to generate images.');
      return;
    }

    if (keywords.trim().split(/\s+/).length > 10) {
      setGenerationError('Please enter no more than 10 words.');
      return;
    }

    setIsGenerating(true);
    setGenerationError('');
    setGeneratedImages([]);
    setSelectedImage('');

    try {
      const response = await fetch('https://testnet.turtleblunt.com/api/generate-image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          prompt: keywords,
          walletAddress: address,
          contractAddress: contractAddress
        }),
      });

      const data = await response.json();

      if (response.ok && data.success && data.ipfsLink) {
        // Use the IPFS link returned by the backend
        const imageUrls = [data.ipfsLink];
        setGeneratedImages(imageUrls);

        // After generation, update previous images list
        await fetchPreviousImages(contractAddress);

        toast.success('Images generated successfully!', { toastId: 'image-generation-success' });
      } else {
        throw new Error(data.error || 'Failed to generate images.');
      }
    } catch (err) {
      console.error('Error generating images:', err);
      setGenerationError(err.message || 'Failed to generate images.');
      toast.error(`Image Generation Error: ${err.message}`, { toastId: 'image-generation-error' });
    } finally {
      setIsGenerating(false);
    }
  };

  const selectImage = (url) => {
    setSelectedImage(url);
    setMetadataImage(url); // Update metadata image automatically
    toast.success('Image selected and added to metadata form!', { toastId: 'image-selected-success' });
  };

  return (
    <Container className={styles.interactorContainer}>
      <img
        src="/images/interactor.png"
        alt="Interactor"
        className={styles.interactorHeaderImage}
      />

      <div className={styles.interactorHoverCard}>
        {!provider ? (
          <div className="d-flex justify-content-center">
            <Button
              variant="primary"
              onClick={connectWallet}
              className={`${styles.interactorButtonPrimary} w-50`}
              disabled={walletLoading}
              aria-label="Connect Wallet"
            >
              {walletLoading ? <Spinner animation="border" size="sm" /> : 'Connect Wallet'}
            </Button>
          </div>
        ) : !isConnected ? (
          <Form onSubmit={connectContract}>
            <Form.Group controlId="contractAddress" className="mb-4">
              <Form.Label className="text-center w-100">Enter Your Token Contract Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="0x..."
                value={contractAddress}
                onChange={handleAddressChange}
                className={`${styles.interactorFormControl} text-center`}
                required
                aria-label="Token Contract Address"
              />
            </Form.Group>
            <div className="d-flex justify-content-center">
              <Button
                variant="primary"
                type="submit"
                className={`${styles.interactorButtonPrimary} w-50`}
                disabled={loading}
                aria-label="Connect to Contract"
              >
                {loading ? <Spinner animation="border" size="sm" /> : 'Connect'}
              </Button>
            </div>
          </Form>
        ) : (
          <div>
            <h5>Connected to {tokenName} ({tokenSymbol})</h5>
            <p>Your Address: {address}</p>
            <div className="d-flex justify-content-center mt-2">
              <Button
                variant="outline-danger"
                onClick={handleDisconnectContract}
                className={`${styles.interactorButtonDanger} w-50`}
                disabled={loading}
                aria-label="Disconnect Contract"
              >
                {loading ? <Spinner animation="border" size="sm" /> : 'Disconnect'}
              </Button>
            </div>
          </div>
        )}
      </div>

      {isConnected && (
        <div className={styles.interactorContentSection}>
          {/* Token Details */}
          <div className={`mb-4 p-3 bg-dark text-light border-0 ${styles.interactorCustomCard}`}>
            <h4 className="text-center">Token Details</h4>
            <Table bordered hover variant="dark" className="mt-3 text-center">
              <tbody>
                <tr>
                  <th className="w-50">Name</th>
                  <td><strong>{tokenName}</strong></td>
                </tr>
                <tr>
                  <th>Symbol</th>
                  <td><strong>{tokenSymbol}</strong></td>
                </tr>
                <tr>
                  <th>Decimals</th>
                  <td><strong>{decimals !== null ? decimals : 'N/A'}</strong></td>
                </tr>
                <tr>
                  <th>Total Supply</th>
                  <td>
                    <div className="bg-secondary p-2 rounded">
                      <strong>{totalSupply} {tokenSymbol}</strong>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Token Price</th>
                  <td>
                    <div className="bg-secondary p-2 rounded">
                      <strong>{tokenPrice} ETH per {tokenSymbol}</strong>
                    </div>
                    <Form onSubmit={handleSetPrice} className="mt-3">
                      <Form.Group controlId="newPrice" className="mb-3">
                        <Form.Label className="text-center w-100">Set New Token Price (ETH)</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter new token price in ETH"
                          value={newPrice}
                          onChange={(e) => setNewPrice(e.target.value)}
                          min="0"
                          step="any"
                          required
                          className={styles.interactorFormControl}
                          aria-label="New Token Price in ETH"
                        />
                      </Form.Group>
                      <div className="d-flex justify-content-center">
                        <Button
                          variant="primary"
                          type="submit"
                          className={`${styles.interactorButtonPrimary} w-50`}
                          disabled={loading}
                          aria-label="Set Token Price"
                        >
                          {loading ? <Spinner animation="border" size="sm" /> : 'Set Token Price'}
                        </Button>
                      </div>
                    </Form>
                  </td>
                </tr>
                <tr>
                  <th>Metadata</th>
                  <td>
                    {metadata ? (
                      <div className="text-start">
                        <p><strong>Name:</strong> {metadata.name}</p>
                        <p><strong>Description:</strong> {metadata.description}</p>
                        {metadata.image && (
                          <div className="text-center">
                            <img
                              src={metadata.image}
                              alt={`${metadata.name} Image`}
                              className="img-fluid rounded"
                              style={{ maxHeight: '200px', objectFit: 'contain' }}
                            />
                          </div>
                        )}
                        {metadata.website && (
                          <p>
                            <strong>Website:</strong>{' '}
                            <a href={metadata.website} target="_blank" rel="noopener noreferrer" className="text-light">
                              {metadata.website}
                            </a>
                          </p>
                        )}
                        {metadata.social && metadata.social.twitter && (
                          <p>
                            <strong>Twitter:</strong>{' '}
                            <a href={metadata.social.twitter} target="_blank" rel="noopener noreferrer" className="text-light">
                              {metadata.social.twitter}
                            </a>
                          </p>
                        )}
                      </div>
                    ) : (
                      <p>No metadata available.</p>
                    )}
                    <div className="mt-3">
                      <strong>Metadata URL:</strong>{' '}
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{metaUrl}</Tooltip>}
                      >
                        <a
                          href={metaUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.interactorMetadataLink}
                        >
                          {metaUrl}
                        </a>
                      </OverlayTrigger>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <Button variant="info" size="sm" onClick={handleOpenMetadataModal} aria-label="Create or Update Metadata">
                        Create/Update Metadata
                      </Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          {/* Generate Images Section (Visible Only to Owner) */}
          {address.toLowerCase() === owner.toLowerCase() && (
            <div className={`mb-4 p-3 bg-dark text-light border-0 ${styles.interactorCustomCard}`}>
              <h4 className="text-center">Generate Images</h4>
              {previousImages && previousImages.length > 0 && (
                <div className="mt-4">
                  <h5 className="text-center">Previously Generated Images:</h5>
                  <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                    {previousImages.map((entry, index) => (
                      <div key={index} className="mb-3">
                        <p><strong>Prompt:</strong> {entry.prompt}</p>
                        <Row className="g-2">
                          {entry.links.map((link, i) => (
                            <Col xs="auto" key={i}>
                              <div
                                className="bg-secondary rounded"
                                style={{
                                  cursor: 'pointer',
                                  width: '60px',
                                  height: '60px',
                                  overflow: 'hidden',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                                onClick={() => handlePrevImageClick(link)}
                                aria-label={`View previous image ${i + 1}`}
                              >
                                <img
                                  src={link}
                                  alt={`Previous ${index + 1}-${i + 1}`}
                                  style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                />
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Image Generation Form */}
              <Form className="mt-3">
                <Form.Group controlId="keywords" className="mb-3">
                  <Form.Label>Keywords (Max 10 words):</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter keywords separated by spaces"
                    value={keywords}
                    onChange={handleKeywordsChange}
                    className={styles.interactorFormControl}
                    aria-label="Image Generation Keywords"
                  />
                  <Form.Text className="text-muted">
                    {keywords.trim() === ''
                      ? 'Enter up to 10 words describing the image you want to create.'
                      : `${10 - keywords.trim().split(/\s+/).length} words remaining`}
                  </Form.Text>
                  {generationError && <p className="text-danger mt-1">{generationError}</p>}
                </Form.Group>
                <div className="d-flex justify-content-center">
                  <Button
                    variant="success"
                    onClick={generateImages}
                    disabled={isGenerating || keywords.trim().split(/\s+/).length === 0 || keywords.trim().split(/\s+/).length > 10}
                    className={`${styles.interactorButtonPrimary} w-50`}
                    aria-label="Generate Images"
                  >
                    {isGenerating ? <Spinner animation="border" size="sm" /> : 'Generate Images'}
                  </Button>
                </div>
              </Form>

              {/* Display Generated Images */}
              {generatedImages.length > 0 && (
                <div className="mt-4">
                  <h5 className="text-center">Newly Generated Images:</h5>
                  <Row className="mt-3">
                    {generatedImages.map((url, index) => (
                      <Col xs={6} md={4} lg={3} key={index} className="mb-3">
                        <div
                          className={`${styles.generatedImageCard} bg-secondary rounded p-2`}
                          onClick={() => selectImage(url)}
                          style={{ cursor: 'pointer', height: '200px', overflow: 'hidden' }}
                          aria-label={`Select generated image ${index + 1}`}
                        >
                          <img
                            src={url}
                            alt={`Generated ${index + 1}`}
                            className="img-fluid rounded"
                            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                          />
                          <p className="text-center mt-2 mb-0">Select Image {index + 1}</p>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              )}

              {/* Display Selected Image */}
              {selectedImage && (
                <div className="mt-4 text-center">
                  <h5>Selected Image:</h5>
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="img-fluid rounded"
                    style={{ maxHeight: '300px', objectFit: 'contain' }}
                  />
                  <p className="mt-2">This image is set as your metadata image.</p>
                </div>
              )}
            </div>
          )}

          {/* Addresses Section */}
          <div className={`mb-4 p-3 bg-dark text-light border-0 ${styles.interactorCustomCard}`}>
            <h4 className="text-center">Addresses</h4>
            <Table bordered hover variant="dark" className="mt-3 text-center">
              <tbody>
                <tr>
                  <th className="w-50">Contract Owner</th>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <a
                        href={getExplorerUrl(owner)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-light me-2"
                        style={{ wordBreak: 'break-all' }}
                        aria-label="Contract Owner Address"
                      >
                        {owner}
                      </a>
                      <Button
                        variant="outline-secondary"
                        onClick={() => copyToClipboard(owner)}
                        size="sm"
                        className={styles.interactorCopyButton}
                        aria-label="Copy Contract Owner Address"
                      >
                        <FaCopy />
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Token Contract Address</th>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <a
                        href={getExplorerUrl(contractAddress)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-light me-2"
                        style={{ wordBreak: 'break-all' }}
                        aria-label="Token Contract Address"
                      >
                        {contractAddress}
                      </a>
                      <Button
                        variant="outline-secondary"
                        onClick={() => copyToClipboard(contractAddress)}
                        size="sm"
                        className={styles.interactorCopyButton}
                        aria-label="Copy Token Contract Address"
                      >
                        <FaCopy />
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Your Address</th>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <a
                        href={getExplorerUrl(address)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-light me-2"
                        style={{ wordBreak: 'break-all' }}
                        aria-label="Your Address"
                      >
                        {address}
                      </a>
                      <Button
                        variant="outline-secondary"
                        onClick={() => copyToClipboard(address)}
                        size="sm"
                        className={styles.interactorCopyButton}
                        aria-label="Copy Your Address"
                      >
                        <FaCopy />
                      </Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          {/* Balances Section */}
          <div className={`mb-4 p-3 bg-dark text-light border-0 ${styles.interactorCustomCard}`}>
            <h4 className="text-center">Balances</h4>
            <Table bordered hover variant="dark" className="mt-3 text-center">
              <tbody>
                <tr>
                  <th className="w-50">Your Token Balance</th>
                  <td>
                    <div className="bg-secondary p-2 rounded">
                      <strong>{userBalance} {tokenSymbol}</strong>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Your ETH Balance</th>
                  <td>
                    <div className="bg-secondary p-2 rounded">
                      <strong>{ethBalance} ETH</strong>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Contract ETH Balance</th>
                  <td>
                    <div className="bg-secondary p-2 rounded">
                      <strong>{contractEthBalance} ETH</strong>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <Button
                        variant="danger"
                        onClick={handleWithdraw}
                        className={`${styles.interactorButtonDanger} w-50`}
                        disabled={Number(contractEthBalance) <= 0 || loading}
                        aria-label="Withdraw ETH"
                      >
                        {loading ? <Spinner animation="border" size="sm" /> : `Withdraw ETH (${contractEthBalance} ETH)`}
                      </Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          {/* User Actions Section */}
          <div className={`mb-4 p-3 bg-dark text-light border-0 ${styles.interactorCustomCard}`}>
            <h4 className="text-center">User Actions</h4>
            <Form onSubmit={handleBurn} className="w-75 mx-auto mb-4 mt-3">
              <Form.Group controlId="burnAmount" className="mb-3">
                <Form.Label className="text-center w-100">Burn Tokens</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter amount to burn"
                  value={burnAmount}
                  onChange={(e) => setBurnAmount(e.target.value)}
                  min="0"
                  step="any"
                  required
                  className={`${styles.interactorFormControl} text-center`}
                  aria-label="Burn Tokens Amount"
                />
              </Form.Group>
              <div className="d-flex justify-content-center">
                <Button
                  variant="danger"
                  type="submit"
                  className={`${styles.interactorButtonDanger} w-50`}
                  disabled={loading}
                  aria-label="Burn Tokens"
                >
                  {loading ? <Spinner animation="border" size="sm" /> : 'Burn Tokens'}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )}

      {/* Metadata Modal */}
      <Modal
        show={showMetadataModal}
        onHide={handleCloseMetadataModal}
        centered
        size="md"
        className={styles.interactorModalContent}
        aria-labelledby="metadata-modal-title"
      >
        <Modal.Header closeButton className={styles.interactorModalHeader}>
          <Modal.Title id="metadata-modal-title">Create Metadata</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleMetadataSubmit}>
            <Form.Group className="mb-3" controlId="metadataName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={metadataName}
                onChange={(e) => setMetadataName(e.target.value)}
                required
                className={styles.interactorFormControl}
                aria-label="Metadata Name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="metadataDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter description"
                value={metadataDescription}
                onChange={(e) => setMetadataDescription(e.target.value)}
                required
                className={styles.interactorFormControl}
                aria-label="Metadata Description"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="metadataImage">
              <Form.Label>Image URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="https://example.com/image.png"
                value={metadataImage}
                onChange={(e) => setMetadataImage(e.target.value)}
                required
                className={styles.interactorFormControl}
                aria-label="Metadata Image URL"
              />
              <Form.Text className="text-muted">
                Provide the URL of the selected image.
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="metadataWebsite">
              <Form.Label>Website URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="https://yourwebsite.com"
                value={metadataWebsite}
                onChange={(e) => setMetadataWebsite(e.target.value)}
                className={styles.interactorFormControl}
                aria-label="Metadata Website URL"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="metadataTwitter">
              <Form.Label>Twitter URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="https://twitter.com/yourprofile"
                value={metadataTwitter}
                onChange={(e) => setMetadataTwitter(e.target.value)}
                className={styles.interactorFormControl}
                aria-label="Metadata Twitter URL"
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button variant="secondary" onClick={handleCloseMetadataModal} className="me-2" aria-label="Cancel Metadata Update">
                Cancel
              </Button>
              <Button variant="primary" type="submit" disabled={loading} aria-label="Submit Metadata">
                {loading ? <Spinner animation="border" size="sm" /> : 'Submit'}
              </Button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className={styles.interactorModalFooter}>
          {/* Optional Footer */}
        </Modal.Footer>
      </Modal>

      {/* Modal to display previously generated image in full size with a "Select Image" button */}
      <Modal
        show={showPrevImageModal}
        onHide={() => setShowPrevImageModal(false)}
        centered
        size="lg"
        aria-labelledby="previous-image-modal-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="previous-image-modal-title">Full-Size Image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {selectedPrevImage && (
            <img
              src={selectedPrevImage}
              alt="Previous Full Image"
              className="img-fluid rounded"
              style={{ maxHeight: '80vh', objectFit: 'contain' }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setSelectedImage(selectedPrevImage);
              setMetadataImage(selectedPrevImage);
              toast.success('Image selected and added to metadata form!', { toastId: 'image-selected-success' });
              setShowPrevImageModal(false);
            }}
            aria-label="Select Image"
          >
            Select Image
          </Button>
          <Button variant="secondary" onClick={() => setShowPrevImageModal(false)} aria-label="Close Image Modal">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default InteractorPage;
