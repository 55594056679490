// src/components/BaseNetworkInfoBar.js

import React from 'react';
import { Container, Card } from 'react-bootstrap';
import './BaseNetworkInfoBar.scss'; // Import the SCSS file

const BaseNetworkInfoBar = () => {
  return (
    <Card className="base-network-info-bar text-center text-white mb-4">
      <Container className="p-5">
        {/* Replace with Base Network's logo */}
        <Card.Img
          variant="top"
          src="/images/base-wordmark.png" // Ensure this path is correct
          alt="Base Wordmark"
          className="base-logo mb-4" // Increased bottom margin
        />
        <Card.Body>
          <Card.Text className="base-info-text">
            <p>
            </p>
            <p>
              Create tokens on Base, an Ethereum L2 that’s secure, low-cost, and builder-friendly.
            </p>
            <p>
              Bring your vision onchain, make it accessible to anyone, anywhere, and help shape a new global economy that empowers innovation and creativity.
            </p>
          </Card.Text>
        </Card.Body>
      </Container>
    </Card>
  );
};

export default BaseNetworkInfoBar;
