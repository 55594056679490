// src/components/TokenSalePage.js

import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Contract, isAddress, parseEther, formatUnits } from 'ethers';
import TokenABI from '../abis/TokenABI.json'; // Ensure the path is correct
import { toast } from 'react-toastify'; 
import {
  Container,
  Form,
  Button,
  Alert,
  Spinner,
  Row,
  Col,
  Card,
  Modal,
  OverlayTrigger,
  Tooltip,
  InputGroup,
} from 'react-bootstrap';
import { Web3Context } from '../Web3Context'; 
import { FaPercentage, FaExpand, FaCopy, FaShoppingCart } from 'react-icons/fa'; 
import ImageWithFallback from './ImageWithFallback'; 
import { copyToClipboard } from '../utils/clipboard'; // Import the utility function
import { formatNumber, formatDate } from '../utils/format'; // Import formatting utilities

import styles from './TokenSalePage.module.scss'; // Import the SCSS module

const TokenSalePage = () => {
  const { tokenAddress } = useParams(); 

  const {
    provider,
    signer,
    address,
    connected,
    loading: walletLoading,
    connectWallet,
    loading,
    walletProvider, // Destructure walletProvider from Web3Context
  } = useContext(Web3Context);

  const [tokenName, setTokenName] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [decimals, setDecimals] = useState(18);
  const [tokenPrice, setTokenPrice] = useState('');
  const [totalSupply, setTotalSupply] = useState('');
  const [metaUrl, setMetaUrl] = useState('');
  const [metadata, setMetadata] = useState(null);
  const [metadataError, setMetadataError] = useState(null);

  const [purchaseAmount, setPurchaseAmount] = useState('');
  const [ethAmount, setEthAmount] = useState('');
  const [purchaseAmountError, setPurchaseAmountError] = useState(null);
  const [ethAmountError, setEthAmountError] = useState(null);
  const [purchaseError, setPurchaseError] = useState(null); // Added purchaseError state
  const [receipt, setReceipt] = useState(null);
  const [showReceipt, setShowReceipt] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [loadingData, setLoadingData] = useState(true);
  const [error, setError] = useState(null);

  const [contract, setContract] = useState(null);
  const [ethBalance, setEthBalance] = useState('0');
  const [ethPriceUSD, setEthPriceUSD] = useState(null); // ETH to USD rate

  // Explorer URL from environment variable
  const explorerUrl = process.env.REACT_APP_EXPLORER_URL || 'https://sepolia.basescan.org/';

  const getExplorerUrl = (txHashOrAddress) => {
    return txHashOrAddress
      ? `${explorerUrl}${txHashOrAddress.length === 66 ? 'tx/' : 'address/'}${txHashOrAddress}`
      : '#';
  };

  const fetchEthBalance = async () => {
    if (provider && address) {
      try {
        const balance = await provider.getBalance(address);
        const formattedBalance = parseFloat(formatUnits(balance, 18)).toFixed(10); // Keep 10 decimals
        setEthBalance(formatNumber(formattedBalance));
        console.log(`Fetched ETH balance: ${formattedBalance} ETH`);
      } catch (err) {
        console.error('Error fetching ETH balance:', err);
        setEthBalance('0.0000000000'); // Ensure it has decimal places
      }
    }
  };

  // Fetch ETH to USD rate dynamically
  const fetchEthPriceUSD = async () => {
    try {
      const response = await fetch(
        'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd'
      );
      if (!response.ok) {
        throw new Error(`CoinGecko API responded with status ${response.status}`);
      }
      const priceData = await response.json();
      const ethPrice = priceData.ethereum.usd;
      setEthPriceUSD(ethPrice);
      console.log(`Fetched ETH price: $${ethPrice} USD`);
    } catch (err) {
      console.error('Error fetching ETH price:', err);
      setEthPriceUSD(null);
    }
  };

  useEffect(() => {
    if (connected && provider && address) {
      fetchEthBalance();
      fetchEthPriceUSD();
      // Refresh ETH price every minute
      const interval = setInterval(() => {
        fetchEthPriceUSD();
      }, 60000); // 60,000 ms = 1 minute
      return () => clearInterval(interval);
    }
  }, [connected, provider, address]);

  const fetchMetadata = async (url) => {
    if (!url || url.trim() === '') {
      setMetadata(null);
      setMetadataError(null);
      return;
    }

    try {
      console.log('Fetching metadata from:', url); 
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch metadata: ${response.statusText}`);
      }
      const data = await response.json();
      setMetadata(data);
      setMetadataError(null);
      return { success: true, data };
    } catch (err) {
      console.error('Error fetching metadata:', err);
      return { success: false, error: err };
    }
  };

  useEffect(() => {
    if (loading) return; 

    const initialize = async () => {
      try {
        if (!isAddress(tokenAddress)) {
          setError('Invalid token address provided.');
          setLoadingData(false);
          return;
        }

        if (!connected || !provider || !signer) {
          setLoadingData(false);
          return;
        }

        const cachedData = localStorage.getItem(`tokenData-${tokenAddress}`);
        if (cachedData) {
          const parsedData = JSON.parse(cachedData);
          const cacheAge = Date.now() - parsedData.cachedAt;
          const maxCacheAge = 5 * 60 * 1000; // 5 minutes

          if (cacheAge < maxCacheAge) {
            setTokenName(parsedData.name);
            setTokenSymbol(parsedData.symbol);
            setDecimals(parsedData.decimals);
            setTotalSupply(formatNumber(formatUnits(parsedData.totalSupply, parsedData.decimals)));
            setTokenPrice(formatNumber(formatUnits(parsedData.tokenPrice, 18)));
            setMetaUrl(parsedData.metaUrl);
            setMetadata(parsedData.metadata || null);
            setMetadataError(parsedData.metadataError || null);
            setLoadingData(false);

            if (parsedData.metaUrl) {
              const metadataResult = await fetchMetadata(parsedData.metaUrl);
              if (metadataResult.success) {
                setMetadata(metadataResult.data);
                const updatedCache = {
                  ...parsedData,
                  metadata: metadataResult.data,
                  metadataError: null,
                  cachedAt: Date.now(),
                };
                localStorage.setItem(`tokenData-${tokenAddress}`, JSON.stringify(updatedCache));
              } else {
                if (!parsedData.metadata) {
                  setMetadataError('Failed to fetch metadata from metaUrl.');
                }
              }
            }

            return;
          } else {
            localStorage.removeItem(`tokenData-${tokenAddress}`);
          }
        }

        const tempContract = new Contract(tokenAddress, TokenABI, signer);
        console.log('Initialized Contract:', tempContract);
        setContract(tempContract);

        const [name, symbol, dec, supply, price, currentMetaUrl] = await Promise.all([
          tempContract.name(),
          tempContract.symbol(),
          tempContract.decimals(),
          tempContract.totalSupply(),
          tempContract.tokenPrice(),
          tempContract.metaUrl ? tempContract.metaUrl() : '',
        ]);

        setTokenName(name);
        setTokenSymbol(symbol);

        let decimalsNum;
        if (typeof dec === 'number') {
          decimalsNum = dec;
        } else if (typeof dec === 'bigint') {
          decimalsNum = Number(dec);
        } else if (dec && typeof dec.toNumber === 'function') {
          decimalsNum = dec.toNumber();
        } else {
          console.warn('Unexpected type for decimals:', dec);
          decimalsNum = 18;
        }
        setDecimals(decimalsNum);

        setTotalSupply(formatNumber(formatUnits(supply, decimalsNum)));
        setTokenPrice(formatNumber(formatUnits(price, 18)));
        setMetaUrl(currentMetaUrl);

        let fetchedMetadata = null;
        let fetchedMetadataError = null;

        if (currentMetaUrl) {
          const metadataResult = await fetchMetadata(currentMetaUrl);
          if (metadataResult.success) {
            fetchedMetadata = metadataResult.data;
          } else {
            fetchedMetadataError = 'Failed to fetch metadata from metaUrl.';
          }
        }

        setMetadata(fetchedMetadata);
        setMetadataError(fetchedMetadataError);

        const tokenDataToCache = {
          name,
          symbol,
          decimals: decimalsNum,
          totalSupply: formatUnits(supply, decimalsNum),
          tokenPrice: formatUnits(price, 18),
          metaUrl: currentMetaUrl,
          metadata: fetchedMetadata,
          metadataError: fetchedMetadataError,
          cachedAt: Date.now(),
        };
        localStorage.setItem(`tokenData-${tokenAddress}`, JSON.stringify(tokenDataToCache));

        setLoadingData(false);
      } catch (err) {
        console.error('Error initializing Token Sale Page:', err);
        setError(
          'Failed to load token details. Please ensure the token address is correct and you are connected to the right network.'
        );
        setLoadingData(false);
      }
    };

    initialize();
  }, [tokenAddress, connected, provider, signer, decimals, loading]);

  useEffect(() => {
    console.log('Contract state updated:', contract);
  }, [contract]);

  const handleTokenAmountChange = (e) => {
    const value = e.target.value;
    setPurchaseAmount(value);
    setPurchaseAmountError(null);
    if (value && !isNaN(value) && parseFloat(tokenPrice) !== 0) {
      const eth = parseFloat(value) * parseFloat(tokenPrice);
      setEthAmount(formatNumber(eth.toFixed(6)));
    } else {
      setEthAmount('');
    }
  };

  const handleEthAmountChange = (e) => {
    let value = e.target.value;
    setEthAmountError(null);
    if (value && !isNaN(value) && Number(value) > Number(ethBalance)) {
      value = ethBalance;
    }
    setEthAmount(formatNumber(value));
    if (value && !isNaN(value) && parseFloat(tokenPrice) !== 0) {
      const tokens = parseFloat(value) / parseFloat(tokenPrice);
      setPurchaseAmount(formatNumber(tokens.toFixed(6)));
    } else {
      setPurchaseAmount('');
    }
  };

  const handlePurchase = async (e) => {
    e.preventDefault();

    setPurchaseAmountError(null);
    setEthAmountError(null);
    setPurchaseError(null); // Reset purchaseError

    if (!purchaseAmount || isNaN(purchaseAmount) || Number(purchaseAmount) <= 0) {
      setPurchaseAmountError('Please enter a valid amount of tokens to purchase.');
      return;
    }

    if (!ethAmount || isNaN(ethAmount) || Number(ethAmount) <= 0) {
      setEthAmountError('Invalid ETH amount.');
      return;
    }

    if (Number(ethAmount) > Number(ethBalance)) {
      setEthAmountError('Insufficient ETH balance.');
      return;
    }

    if (!contract) {
      setPurchaseError('Smart contract is not initialized.');
      toast.error('Smart contract is not initialized.', {
        toastId: 'purchase-contract-error',
      });
      return;
    }

    // Check if 'buyTokens' exists in the contract
    if (typeof contract.buyTokens !== 'function') {
      setPurchaseError('buyTokens function is not available in the contract.');
      toast.error('buyTokens function is not available in the contract.', {
        toastId: 'purchase-buyTokens-error',
      });
      return;
    }

    try {
      setProcessing(true);
      const requiredETH = parseEther(ethAmount.toString());
      const tx = await contract.buyTokens({ value: requiredETH });
      toast.info('Transaction submitted. Awaiting confirmation...', {
        toastId: 'purchase-tx-submitted',
      });

      const receiptTx = await tx.wait();
      if (!receiptTx || (!receiptTx.transactionHash && !receiptTx.hash)) {
        throw new Error('Transaction receipt is missing the transaction hash.');
      }

      const txHash = receiptTx.transactionHash || receiptTx.hash;
      toast.success('Tokens purchased successfully!', {
        toastId: 'purchase-success',
      });

      const purchasedTokens = purchaseAmount;
      const spentETH = ethAmount;
      const timestamp = formatDate(new Date().toISOString());

      setReceipt({
        purchasedTokens,
        spentETH,
        txHash,
        timestamp,
      });
      setShowReceipt(true);
      setPurchaseAmount('');
      setEthAmount('');
      fetchEthBalance();
      fetchEthPriceUSD(); // Update ETH price after purchase
    } catch (err) {
      console.error('Error purchasing tokens:', err);
      if (err.code === 4001) {
        toast.error('Transaction rejected by user.', {
          toastId: 'purchase-tx-rejected',
        });
      } else {
        toast.error('Failed to purchase tokens.', {
          toastId: 'purchase-tx-failed',
        });
        setPurchaseError('Failed to purchase tokens. Please try again.');
      }
    } finally {
      setProcessing(false);
    }
  };

  const handleSetHalfBalance = () => {
    const halfBalance = (Number(ethBalance) / 2).toFixed(6);
    setEthAmount(formatNumber(halfBalance));
    if (parseFloat(tokenPrice) !== 0) {
      const tokens = parseFloat(halfBalance) / parseFloat(tokenPrice);
      setPurchaseAmount(formatNumber(tokens.toFixed(6)));
    }
  };

  const handleSetMaxBalance = () => {
    const maxBalance = Number(ethBalance).toFixed(6);
    setEthAmount(formatNumber(maxBalance));
    if (parseFloat(tokenPrice) !== 0) {
      const tokens = parseFloat(maxBalance) / parseFloat(tokenPrice);
      setPurchaseAmount(formatNumber(tokens.toFixed(6)));
    }
  };

  const handleCloseReceipt = () => {
    setShowReceipt(false);
    // Keep the receipt so it's shown at the bottom
  };

  const truncateHash = (hash) => {
    if (!hash) return "";
    return `${hash.substring(0, 6)}...${hash.substring(hash.length - 4)}`;
  };

  const usdEstimate = ethAmount && ethPriceUSD ? (parseFloat(ethAmount) * ethPriceUSD).toFixed(2) : null;

  const addTokenToWallet = async () => {
    if (!tokenAddress || !tokenSymbol || !decimals) {
      toast.error('Token information is incomplete.', { toastId: 'add-token-missing-info' });
      console.log('Add Token Error: Token information is incomplete.');
      return;
    }

    const tokenImage = metadata?.image || 'https://yourdomain.com/path-to-default-token-image.png'; // Ensure you have a valid image URL
    console.log('Adding token with details:', { tokenAddress, tokenSymbol, decimals, tokenImage });

    // Prepare the parameters for wallet_watchAsset
    const tokenDetails = {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: decimals,
        image: tokenImage, // Ensure this is a valid, accessible URL
      },
    };

    // Check if wallet_watchAsset is supported
    const support = isWalletWatchAssetSupported();
    if (!support) {
      // Provide fallback instructions
      toast.warn('Your wallet does not support adding tokens automatically. Please add the token manually.', { toastId: 'add-token-warning' });
      console.log('wallet_watchAsset not supported. Providing manual instructions.');
      return;
    }

    try {
      // Use walletProvider directly, else fallback to window.ethereum
      const walletProviderInstance = walletProvider || window.ethereum;
      console.log('walletProviderInstance:', walletProviderInstance);

      if (!walletProviderInstance || typeof walletProviderInstance.request !== 'function') {
        throw new Error('Wallet provider is not available.');
      }

      const wasAdded = await walletProviderInstance.request({
        method: 'wallet_watchAsset',
        params: tokenDetails,
      });

      console.log('wasAdded:', wasAdded);

      if (wasAdded) {
        toast.success(`Token ${tokenSymbol} has been added to your wallet!`, { toastId: 'add-token-success' });
        console.log(`Token ${tokenSymbol} added successfully.`);
      } else {
        toast.info(`Token ${tokenSymbol} was not added to your wallet.`, { toastId: 'add-token-info' });
        console.log(`Token ${tokenSymbol} was not added.`);
      }
    } catch (error) {
      console.error('Error adding token to wallet:', error);
      toast.error(`Failed to add token to wallet: ${error.message}`, { toastId: 'add-token-error' });
    }
  };

  const isWalletWatchAssetSupported = () => {
    const walletProviderInstance = walletProvider || window.ethereum;
    return (
      walletProviderInstance &&
      typeof walletProviderInstance.request === 'function'
    );
  };

  return (
    <Container className={`${styles.tokenSaleContainer}`}>
      <Card className={`${styles.card}`}>
        {/* Header Section */}
        <div className={styles.header}>
          <img
            src="/images/tokensale-header.png" // Replace with your header image
            alt="Token Sale Header"
            className={styles.headerImage}
          />
          <h2>Token Sale</h2>
          <p>Purchase {tokenSymbol} tokens using ETH.</p>
        </div>

        {/* Wallet Connection or Loading */}
        {loading || walletLoading ? (
          <div className="text-center">
            <Spinner animation="border" variant="light" />
            <p className="mt-3">Initializing wallet connection...</p>
          </div>
        ) : !connected ? (
          <div className="d-flex flex-column align-items-center">
            <Button
              variant="primary"
              onClick={connectWallet}
              disabled={walletLoading}
              className="w-75 w-md-50 mb-3"
            >
              {walletLoading ? <Spinner animation="border" size="sm" /> : 'Connect Wallet'}
            </Button>
            <Alert variant="info" className="text-center w-75 w-md-50">
              To purchase tokens, please connect your wallet using the button above.
            </Alert>
          </div>
        ) : (
          <>
            {loadingData ? (
              <div className="text-center">
                <Spinner animation="border" variant="light" />
                <p className="mt-3">Loading token details...</p>
              </div>
            ) : error ? (
              <Alert variant="danger" className="text-center">
                {error}
              </Alert>
            ) : (
              <>
                {/* Token Image */}
                {metadata && metadata.image && (
                  <Row className="justify-content-center mb-4">
                    <Col xs={12} sm={8} md={6} lg={4} className="text-center">
                      <ImageWithFallback
                        src={metadata.image}
                        alt={`${metadata.name || tokenName} Image`}
                        className={styles.tokenImage}
                      />
                    </Col>
                  </Row>
                )}

                {/* Metadata Error */}
                {metadataError && (
                  <Alert variant="warning" className="text-center">
                    {metadataError}
                  </Alert>
                )}

                {/* Token Description */}
                {metadata && metadata.description && (
                  <div className="mb-4 text-center">
                    <p>{metadata.description}</p>
                  </div>
                )}

                {/* Social Links */}
                {metadata && (
                  <div className="mb-4 text-center">
                    {metadata.website && (
                      <p>
                        <strong>Website: </strong>
                        <a
                          href={metadata.website}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary"
                        >
                          {metadata.website}
                        </a>
                      </p>
                    )}
                    {metadata.social && (
                      <div>
                        {metadata.social.twitter && (
                          <p>
                            <strong>Twitter: </strong>
                            <a
                              href={metadata.social.twitter}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-primary"
                            >
                              {metadata.social.twitter}
                            </a>
                          </p>
                        )}
                        {metadata.social.discord && (
                          <p>
                            <strong>Discord: </strong>
                            <a
                              href={metadata.social.discord}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-primary"
                            >
                              {metadata.social.discord}
                            </a>
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                )}

                {/* Contract Address */}
                <div className="d-flex justify-content-center mb-4">
                  <Card
                    className="p-2 bg-secondary text-white border-0 d-flex flex-row align-items-center flex-wrap"
                    style={{ maxWidth: '600px' }}
                  >
                    <strong>Contract Address: </strong>
                    <a
                      href={getExplorerUrl(tokenAddress)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white me-2 text-break"
                      style={{ textDecoration: 'underline', fontSize: '0.85rem' }}
                    >
                      {tokenAddress}
                    </a>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Copy Address</Tooltip>}
                    >
                      <Button
                        variant="outline-secondary"
                        onClick={() => copyToClipboard(tokenAddress)}
                        size="sm"
                        className="ms-2"
                        aria-label="Copy Contract Address"
                      >
                        <FaCopy />
                      </Button>
                    </OverlayTrigger>
                  </Card>
                </div>

                {/* Token Details */}
                <Card className="mb-4 p-3 bg-dark text-light border-0">
                  <Row className="justify-content-center text-center">
                    <Col xs={12} md={4} className="mb-3">
                      <h5>{tokenName}</h5>
                      <p className="mb-0">{tokenSymbol}</p>
                    </Col>
                    <Col xs={12} md={4} className="mb-3">
                      <h5>{decimals}</h5>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Number of decimal places</Tooltip>}
                      >
                        <p className="mb-0">Decimals</p>
                      </OverlayTrigger>
                    </Col>
                    <Col xs={12} md={4} className="mb-3">
                      <h5>{totalSupply}</h5>
                      <p className="mb-0">Total Supply</p>
                    </Col>
                  </Row>
                </Card>

                {/* Purchase Form */}
                <Card className="p-4 bg-dark text-light border-0">
                  <Form onSubmit={handlePurchase}>
                    {/* ETH Balance Display */}
                    <Row className="justify-content-center mb-3">
                      <Col xs={12} md={6} className="text-center">
                        <Alert variant="info" className="py-2">
                          Current ETH Balance: {ethBalance} ETH
                        </Alert>
                      </Col>
                    </Row>

                    {/* ETH Amount Input */}
                    <Row className="justify-content-center mb-4">
                      <Col xs={12} md={6} lg={4}>
                        <Form.Group controlId="ethAmount" className="mb-3">
                          <Form.Label className={styles.formLabel}>Amount of ETH</Form.Label>
                          <InputGroup className={styles.inputGroup}>
                            <Form.Control
                              type="number"
                              placeholder="Enter amount of ETH"
                              value={ethAmount}
                              onChange={handleEthAmountChange}
                              min="0"
                              step="any"
                              required
                              className={styles.formControl}
                              disabled={processing}
                              max={ethBalance}
                              aria-label="Amount of ETH to spend"
                            />
                            <Button
                              variant="secondary"
                              onClick={handleSetHalfBalance}
                              disabled={processing}
                              title="Set to Half of Balance"
                              className={styles.addonButton}
                              aria-label="Set Half Balance"
                            >
                              <FaPercentage />
                            </Button>
                            <Button
                              variant="secondary"
                              onClick={handleSetMaxBalance}
                              disabled={processing}
                              title="Set to Max Balance"
                              className={styles.addonButton}
                              aria-label="Set Max Balance"
                            >
                              <FaExpand />
                            </Button>
                          </InputGroup>
                          {ethAmountError && (
                            <div className={styles.errorText}>{ethAmountError}</div>
                          )}
                          {usdEstimate && (
                            <div className={styles.usdEstimate}>
                              ≈ ${usdEstimate} USD
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* Token Amount Input */}
                    <Row className="justify-content-center mb-4">
                      <Col xs={12} md={6} lg={4}>
                        <Form.Group controlId="purchaseAmount" className="mb-3">
                          <Form.Label className={styles.formLabel}>Amount of {tokenSymbol}</Form.Label>
                          <InputGroup className={styles.inputGroup}>
                            <Form.Control
                              type="number"
                              placeholder={`Enter number of ${tokenSymbol}`}
                              value={purchaseAmount}
                              onChange={handleTokenAmountChange}
                              min="0"
                              step="any"
                              required
                              className={styles.formControl}
                              disabled={processing}
                              aria-label={`Amount of ${tokenSymbol} to purchase`}
                            />
                          </InputGroup>
                          {purchaseAmountError && (
                            <div className={styles.errorText}>{purchaseAmountError}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* Purchase Button */}
                    <Row className="justify-content-center">
                      <Col xs={12} md={6} lg={4} className="text-center">
                        <div className={styles.purchaseButton}>
                          <Button
                            variant="primary"
                            type="submit"
                            disabled={processing || Number(ethAmount) > Number(ethBalance)}
                            className="w-100"
                            aria-label="Purchase Tokens"
                          >
                            {processing ? (
                              <>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  className="me-2"
                                />
                                Processing...
                              </>
                            ) : (
                              'Purchase Tokens'
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    {/* Error Alert */}
                    {purchaseError && (
                      <Row className="justify-content-center">
                        <Col xs={12} md={6} lg={4} className="text-center">
                          <Alert variant="danger" className="mt-3">
                            {purchaseError}
                          </Alert>
                        </Col>
                      </Row>
                    )}
                  </Form>
                </Card>
              </>
            )}
          </>
        )}

        {error && connected && (
          <Alert variant="danger" className="mt-3 text-center">
            {error}
          </Alert>
        )}

        {/* Receipt Modal */}
        <Modal show={showReceipt} onHide={handleCloseReceipt} centered>
          <Modal.Header closeButton className="bg-dark text-light">
            <Modal.Title>Purchase Receipt</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-dark text-light">
            {receipt ? (
              <Container>
                <Row className="mb-2">
                  <Col><strong>Tokens Purchased:</strong></Col>
                  <Col className="text-end">{receipt.purchasedTokens} {tokenSymbol}</Col>
                </Row>
                <Row className="mb-2">
                  <Col><strong>ETH Spent:</strong></Col>
                  <Col className="text-end">{receipt.spentETH} ETH</Col>
                </Row>
                <Row className="mb-2 align-items-center">
                  <Col><strong>Transaction Hash:</strong></Col>
                  <Col className="text-end d-flex align-items-center justify-content-end">
                    {receipt.txHash ? (
                      <>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Copy Transaction Hash</Tooltip>}
                        >
                          <a
                            href={getExplorerUrl(receipt.txHash)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary me-2"
                            style={{ wordBreak: "break-all", textDecoration: "underline" }}
                          >
                            {truncateHash(receipt.txHash)}
                          </a>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Copy Transaction Hash</Tooltip>}
                        >
                          <Button
                            variant="outline-light"
                            size="sm"
                            onClick={() => copyToClipboard(receipt.txHash)}
                            className={styles.copyButton}
                            aria-label="Copy Transaction Hash"
                          >
                            <FaCopy />
                          </Button>
                        </OverlayTrigger>
                      </>
                    ) : (
                      "Transaction hash unavailable."
                    )}
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col><strong>Timestamp:</strong></Col>
                  <Col className="text-end">{receipt.timestamp}</Col>
                </Row>
              </Container>
            ) : (
              <p>No receipt information available.</p>
            )}
          </Modal.Body>
          <Modal.Footer className="bg-dark text-light">
            <Button variant="primary" onClick={handleCloseReceipt}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Persistent Receipt Display */}
        {receipt && (
          <Card className={`${styles.receiptCard}`}>
            <Card.Body>
              <Card.Title className={styles.receiptTitle}>Purchase Receipt</Card.Title>
              <Row className="mb-3">
                <Col xs={6}><strong>Tokens Purchased:</strong></Col>
                <Col xs={6} className="text-end">{receipt.purchasedTokens} {tokenSymbol}</Col>
              </Row>
              <Row className="mb-3">
                <Col xs={6}><strong>ETH Spent:</strong></Col>
                <Col xs={6} className="text-end">{receipt.spentETH} ETH</Col>
              </Row>
              <Row className="mb-3 align-items-center">
                <Col xs={6}><strong>Transaction Hash:</strong></Col>
                <Col xs={6} className="text-end d-flex align-items-center justify-content-end">
                  {receipt.txHash ? (
                    <>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Copy Transaction Hash</Tooltip>}
                      >
                        <a
                          href={getExplorerUrl(receipt.txHash)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary me-2"
                          style={{
                            textDecoration: "underline",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "block",
                            maxWidth: "200px",
                            fontFamily: "'Courier New', Courier, monospace",
                          }}
                        >
                          {truncateHash(receipt.txHash)}
                        </a>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Copy Transaction Hash</Tooltip>}
                      >
                        <Button
                          variant="outline-light"
                          size="sm"
                          onClick={() => copyToClipboard(receipt.txHash)}
                          className={styles.copyButton}
                          aria-label="Copy Transaction Hash"
                        >
                          <FaCopy />
                        </Button>
                      </OverlayTrigger>
                    </>
                  ) : (
                    "Transaction hash unavailable."
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={6}><strong>Timestamp:</strong></Col>
                <Col xs={6} className="text-end">{receipt.timestamp}</Col>
              </Row>

              {/* Add Token to Wallet Button */}
              <div className={styles.addToWalletButton}>
                <Button variant="info" onClick={addTokenToWallet} className="rounded-pill" aria-label={`Add ${tokenSymbol} to Wallet`}>
                  Add {tokenSymbol} to Wallet
                </Button>
              </div>
            </Card.Body>
          </Card>
        )}
      </Card>
    </Container>
  );
};

// Helper function to validate Ethereum addresses
const isAddressValid = (address) => {
  return isAddress(address);
};

export default TokenSalePage;
