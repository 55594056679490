import React, { useState } from 'react';
import { FaCheckCircle, FaTimesCircle, FaInfoCircle } from 'react-icons/fa';
import './ContractFeaturesPanel.scss';

const ContractFeaturesPanel = () => {
  const featureTooltips = {
    'ERC20 Standard': 'Ensures compatibility with wallets and exchanges by following the ERC20 interface.',
    'Permit (EIP-2612)': 'Allows approvals via signatures (gasless). Users can set allowances without paying gas.',
    'Ownable': 'Gives an owner control over certain admin tasks, like updating prices or metadata.',
    'Reentrancy Guard': 'Prevents malicious contracts from using reentrancy attacks.',
    'Burnable': 'Holders can destroy their tokens, reducing total supply.',
    'Set Token Price': 'Owner can set or update the token’s sale price for direct sales.',
    'Metadata URL': 'Stores and updates a URL for extended token information (images, descriptions).',
    'Capped Supply': 'No minting function, so initial supply is the max supply.',
    'Mintable': 'Would allow creating new tokens after initial supply (not included).',
    'Pausable': 'Would allow halting all transfers in emergencies (not included).',
    'Timelock': 'Would delay sensitive operations for added security (not included).',
    'Whitelisting': 'Would restrict actions to approved addresses (not included).',
    'Freezable Accounts': 'Would allow freezing certain accounts (not included).',
    'Snapshot': 'Would record balances at specific blocks for governance (not included).',
    'Votes (Governance)': 'Would integrate on-chain voting mechanisms for governance (not included).',
  };

  const features = [
    { name: 'ERC20 Standard', included: true },
    { name: 'Permit (EIP-2612)', included: true },
    { name: 'Ownable', included: true },
    { name: 'Reentrancy Guard', included: true },
    { name: 'Burnable', included: true },
    { name: 'Set Token Price', included: true },
    { name: 'Metadata URL', included: true },
    { name: 'Capped Supply', included: true },
    { name: 'Mintable', included: false },
    { name: 'Pausable', included: false },
    { name: 'Timelock', included: false },
    { name: 'Whitelisting', included: false },
    { name: 'Freezable Accounts', included: false },
    { name: 'Snapshot', included: false },
    { name: 'Votes (Governance)', included: false },
  ];

  const includedFeatures = features.filter(f => f.included);
  const excludedFeatures = features.filter(f => !f.included);

  // State to track visibility of info text for each feature.
  // This could be a single object keyed by feature name, for example.
  const [infoVisibility, setInfoVisibility] = useState({});

  const toggleInfo = (featureName) => {
    setInfoVisibility(prev => ({
      ...prev,
      [featureName]: !prev[featureName]
    }));
  };

  const renderFeature = (feature) => {
    const isVisible = infoVisibility[feature.name];
    return (
      <div className="feature-item" key={feature.name}>
        {feature.included ? (
          <FaCheckCircle className="text-success" />
        ) : (
          <FaTimesCircle className="text-danger" />
        )}
        <span>{feature.name}</span>
        <FaInfoCircle
          className="info-icon"
          onClick={() => toggleInfo(feature.name)}
          title="Click for more info"
          style={{ cursor: 'pointer', marginLeft: '8px' }}
        />
        {isVisible && (
          <span className="info-text" style={{ marginLeft: '8px', fontStyle: 'italic', color: '#555' }}>
            {featureTooltips[feature.name]}
          </span>
        )}
      </div>
    );
  };

  return (
    <div className="contract-features-panel">
      <h3>Contract Features</h3>
      <div className="features-row">
        <div className="feature-list">
          <h4>Included Features</h4>
          {includedFeatures.map(renderFeature)}
        </div>
        <div className="feature-list">
          <h4>Not Included</h4>
          {excludedFeatures.map(renderFeature)}
        </div>
      </div>
      <div className="verification-status">
        <p>
          <strong>📜 Verification Status:</strong> This contract source code is verified as a similar match due to being created through another smart contract. Exact match verification is unavailable because identical source code is already published, and BaseScan does not allow submission of constructor args for tokens with existing exact matches.
        </p>
      </div>
    </div>
  );
};

export default ContractFeaturesPanel;
