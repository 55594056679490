// src/components/BuyTbluntPanel.js

import React, { useState, useEffect } from "react";
import {
  Card,
  Spinner,
  Alert,
  Button,
  Form,
  Modal,
  InputGroup,
  Row,
  Col,
  Container,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Contract, formatUnits, parseEther } from "ethers";
import TokenCreationManagerAbi from "../abis/TokenCreationManagerAbi.json";
import TBLUNTAbi from "../abis/TbluntToken.json";
import { useWeb3 } from "../Web3Context";
import { copyToClipboard } from "../utils/clipboard"; 
import { toast } from "react-toastify"; 
import { addTokenToWallet } from "../utils/addTokenToWallet";
import { FaEthereum, FaDollarSign, FaShoppingCart, FaCopy } from "react-icons/fa";
import { formatNumber, formatDate } from "../utils/format"; // Import both utilities
import styles from './BuyTbluntPanel.module.scss'; // Import as CSS Module

const BuyTbluntPanel = () => {
  const {
    provider,
    signer,
    address,
    connected,
    loading: walletLoading,
    connectWallet,
  } = useWeb3();

  const [tokenPrice, setTokenPrice] = useState(null);
  const [creationFee, setCreationFee] = useState(null);
  const [ethPriceUSD, setEthPriceUSD] = useState(null);
  const [usdCost, setUsdCost] = useState(null);
  const [loading, setLoading] = useState(true); 
  const [dataError, setDataError] = useState(null);

  const [tbluntBalance, setTbluntBalance] = useState("0");
  const [purchaseAmount, setPurchaseAmount] = useState("");
  const [ethAmount, setEthAmount] = useState("");
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [purchaseError, setPurchaseError] = useState(null);
  const [receipt, setReceipt] = useState(null);
  const [showReceipt, setShowReceipt] = useState(false);

  const [purchaseAmountError, setPurchaseAmountError] = useState(null);
  const [ethAmountError, setEthAmountError] = useState(null);

  const [tokenSymbol, setTokenSymbol] = useState("");
  const [decimals, setDecimals] = useState(18);

  const [ethBalance, setEthBalance] = useState("0");

  const tokenCreationManagerAddress =
    process.env.REACT_APP_TOKEN_CREATION_MANAGER_ADDRESS;
  const tbluntTokenAddress = process.env.REACT_APP_TBLUNT_TOKEN_ADDRESS;

  const getExplorerUrl = (txHashOrAddress) => {
    const explorerUrl =
      process.env.REACT_APP_EXPLORER_URL || "https://basescan.org/";
    return txHashOrAddress
      ? `${explorerUrl}${
          txHashOrAddress.length === 66 ? "tx/" : "address/"
        }${txHashOrAddress}`
      : "#";
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!connected || !provider || !signer || !address) {
        return;
      }

      try {
        setLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 500));

        const tokenCreationManagerContract = new Contract(
          tokenCreationManagerAddress,
          TokenCreationManagerAbi.abi,
          signer
        );

        const tbluntContract = new Contract(
          tbluntTokenAddress,
          TBLUNTAbi.abi,
          signer
        );

        // **Fetch Symbol and Decimals First**
        const fetchedTokenSymbol = await tbluntContract.symbol();
        const fetchedDecimals = await tbluntContract.decimals();
        setTokenSymbol(fetchedTokenSymbol);
        setDecimals(fetchedDecimals);

        console.log(`Fetched Decimals: ${fetchedDecimals}`);

        // **Fetch Token Price and Creation Fee**
        const currentTokenPrice = await tbluntContract.tokenPrice();
        setTokenPrice(currentTokenPrice);

        const currentCreationFee = await tokenCreationManagerContract.creationFee();
        setCreationFee(currentCreationFee);

        // **Fetch ETH Price from CoinGecko**
        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
        );

        if (!response.ok) {
          throw new Error(
            `CoinGecko API responded with status ${response.status}`
          );
        }

        const priceData = await response.json();
        const ethPrice = priceData.ethereum.usd;
        setEthPriceUSD(ethPrice);

        // **Calculate Estimated Cost in USD**
        const creationFeeInTblunt = parseFloat(
          formatUnits(currentCreationFee, fetchedDecimals)
        );
        const tokenPriceInEth = parseFloat(
          formatUnits(currentTokenPrice, "ether")
        );
        const estimatedCostUSD = formatNumber(
          parseFloat((creationFeeInTblunt * tokenPriceInEth * ethPrice).toFixed(2))
        );
        setUsdCost(estimatedCostUSD);

        console.log(`Fetched creationFee: ${creationFeeInTblunt}`);

        // **Fetch User's TBLUNT Balance**
        const userTbluntBalance = await tbluntContract.balanceOf(address);
        const formattedTbluntBalance = parseFloat(formatUnits(userTbluntBalance, fetchedDecimals));
        setTbluntBalance(formatNumber(formattedTbluntBalance));
        console.log(`Raw TBLUNT Balance: ${userTbluntBalance.toString()}`);
        console.log(`Formatted TBLUNT Balance: ${formattedTbluntBalance}`);

        // **Fetch User's ETH Balance**
        const userEthBalance = await provider.getBalance(address);
        const formattedEthBalance = parseFloat(formatUnits(userEthBalance, "ether")).toFixed(6);
        setEthBalance(formatNumber(formattedEthBalance));
        console.log(`ETH Balance: ${formattedEthBalance}`);

        setDataError(null);
      } catch (err) {
        console.error("Error fetching BuyTbluntPanel data:", err);
        setDataError(
          err.message ||
            "An unexpected error occurred while fetching data."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    connected,
    provider,
    signer,
    address,
    tokenCreationManagerAddress,
    tbluntTokenAddress,
    decimals,
  ]);

  const handlePurchaseAmountChange = (e) => {
    const value = e.target.value;
    setPurchaseAmount(value);
    setPurchaseError(null);
    setPurchaseAmountError(null);
    if (value && !isNaN(value) && tokenPrice) {
      const eth = parseFloat(value) * parseFloat(formatUnits(tokenPrice, "ether"));
      setEthAmount(eth.toFixed(6));
    } else {
      setEthAmount("");
    }
  };

  const handleEthAmountChange = (e) => {
    const value = e.target.value;
    setEthAmount(value);
    setPurchaseError(null);
    setEthAmountError(null);
    if (value && !isNaN(value) && tokenPrice) {
      const tokens = parseFloat(value) / parseFloat(formatUnits(tokenPrice, "ether"));
      setPurchaseAmount(tokens.toFixed(6));
    } else {
      setPurchaseAmount("");
    }
  };

  const performPurchase = async (purchaseAmountValue, ethAmountValue) => {
    if (!purchaseAmountValue || isNaN(purchaseAmountValue) || Number(purchaseAmountValue) <= 0) {
      setPurchaseAmountError("Please enter a valid amount of tokens to purchase.");
      return;
    }

    if (!ethAmountValue || isNaN(ethAmountValue) || Number(ethAmountValue) <= 0) {
      setPurchaseError("Invalid ETH amount.");
      return;
    }

    if (Number(ethAmountValue) > Number(ethBalance)) {
      setEthAmountError(`You have only ${ethBalance} ETH available.`);
      toast.warn(`You have only ${ethBalance} ETH available.`, {
        toastId: "eth-limit-warning",
      });
      return;
    }

    try {
      setPurchaseLoading(true);

      const tbluntContract = new Contract(tbluntTokenAddress, TBLUNTAbi.abi, signer);

      const requiredETH = parseEther(ethAmountValue.toString());
      const tx = await tbluntContract.buyTokens({ value: requiredETH });

      toast.info("Transaction submitted. Awaiting confirmation...", {
        toastId: "purchase-tx-submitted",
      });

      const receiptTx = await tx.wait();

      console.log("Transaction Receipt:", receiptTx);
      console.log("Transaction Hash:", receiptTx.hash); 

      const txHash = receiptTx.hash;

      if (!txHash) {
        throw new Error("Transaction hash is unavailable.");
      }

      const block = await provider.getBlock(receiptTx.blockNumber);
      const timestamp = block.timestamp
        ? formatDate(new Date(block.timestamp * 1000).toISOString())
        : "N/A";

      toast.success("Tokens purchased successfully!", { toastId: "purchase-success" });

      const newReceipt = {
        purchasedTokens: purchaseAmountValue,
        spentETH: ethAmountValue,
        txHash: txHash,
        blockNumber: receiptTx.blockNumber,
        timestamp: timestamp,
      };
      setReceipt(newReceipt);
      setShowReceipt(true);

      setPurchaseAmount("");
      setEthAmount("");

      const userTbluntBalance = await tbluntContract.balanceOf(address);
      const formattedTbluntBalance = parseFloat(formatUnits(userTbluntBalance, decimals));
      setTbluntBalance(formatNumber(formattedTbluntBalance));

      const updatedEthBalance = await provider.getBalance(address);
      const formattedEthBalance = parseFloat(formatUnits(updatedEthBalance, "ether")).toFixed(6);
      setEthBalance(formatNumber(formattedEthBalance));
    } catch (err) {
      console.error("Error purchasing tokens:", err);
      if (err.code === 4001) {
        toast.error("Transaction rejected by user.", {
          toastId: "purchase-tx-rejected",
        });
      } else if (err.message.includes("revert")) {
        toast.error(`Transaction failed: ${err.reason || err.message}`, {
          toastId: "purchase-tx-failed",
        });
      } else {
        toast.error("Failed to purchase tokens.", {
          toastId: "purchase-tx-failed",
        });
      }
    } finally {
      setPurchaseLoading(false);
    }
  };

  const handlePurchase = async (e) => {
    e.preventDefault();
    await performPurchase(purchaseAmount, ethAmount);
  };

  const handleCloseReceipt = () => {
    setShowReceipt(false);
  };

  const handleAddTokenToWallet = async () => {
    const tokenAddress = tbluntTokenAddress;
    const tokenSymbolEnv = process.env.REACT_APP_TBLUNT_TOKEN_SYMBOL || tokenSymbol;
    const tokenDecimalsEnv = parseInt(process.env.REACT_APP_TBLUNT_TOKEN_DECIMALS) || decimals;
    const tokenImage = process.env.REACT_APP_TBLUNT_TOKEN_IMAGE_URL || "";

    console.log('handleAddTokenToWallet called with the following parameters:');
    console.log(`Token Address: ${tokenAddress}`);
    console.log(`Token Symbol: ${tokenSymbolEnv}`);
    console.log(`Token Decimals: ${tokenDecimalsEnv}`);
    console.log(`Token Image: ${tokenImage}`);

    await addTokenToWallet({
      tokenAddress,
      tokenSymbol: tokenSymbolEnv,
      tokenDecimals: tokenDecimalsEnv,
      tokenImage,
    });
  };

  const handleBuyFixedAmount = async () => {
    if (!creationFee || !tokenPrice) {
      toast.error("Cannot perform purchase. Missing creation fee or token price.", {
        toastId: "fixed-purchase-error",
      });
      return;
    }

    const fixedPurchaseAmount = parseFloat(formatUnits(creationFee, decimals));
    const fixedEthAmount = fixedPurchaseAmount * parseFloat(formatUnits(tokenPrice, "ether"));

    await performPurchase(fixedPurchaseAmount.toString(), fixedEthAmount.toFixed(6));
  };

  if (walletLoading || loading) {
    return (
      <Card className="p-4 bg-dark text-light buy-tblunt-panel">
        <Card.Body className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </Card.Body>
      </Card>
    );
  }

  if (dataError) {
    return (
      <Card className="p-4 bg-dark text-light buy-tblunt-panel">
        <Card.Body>
          <Alert variant="danger">Error: {dataError}</Alert>
        </Card.Body>
      </Card>
    );
  }

  if (!connected) {
    return (
      <Card className="p-4 bg-dark text-light buy-tblunt-panel">
        <Card.Body className="d-flex flex-column align-items-center">
          <Button
            variant="primary"
            onClick={connectWallet}
            disabled={walletLoading}
            className="w-75 w-md-50 mb-3"
            aria-label="Connect Wallet"
          >
            {walletLoading ? <Spinner animation="border" size="sm" /> : "Connect Wallet"}
          </Button>
          <Alert variant="info" className="text-center w-75 w-md-50">
            To purchase TBLUNT tokens, please connect your wallet using the button above.
          </Alert>
        </Card.Body>
      </Card>
    );
  }

  const truncateHash = (hash) => {
    if (!hash) return "";
    return `${hash.substring(0, 6)}...${hash.substring(hash.length - 4)}`;
  };

  const usdEstimate = ethAmount && ethPriceUSD ? formatNumber((parseFloat(ethAmount) * ethPriceUSD).toFixed(2)) : null;

  return (
    <Card className="p-4 bg-dark text-light buy-tblunt-panel">
      <Card.Body>
        {/* Centered, simpler layout */}
        <div className="text-center">
          {/* Creation Fee Section */}
          <div className="mb-4">
            <h5 className="text-success" style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>Creation Fee</h5>
            {creationFee !== null && creationFee !== undefined ? `${formatUnits(creationFee, decimals)} TBLUNT` : "Loading..."}<br />
            {usdCost ? <span className="text-info">${usdCost} USD</span> : ""}
          </div>

          {/* TBLUNT Balance Section */}
          <div className="mb-4">
            <h5 className="text-success" style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>TBLUNT Balance</h5>
            {tbluntBalance} TBLUNT
          </div>
        </div>

        {/* Purchase Form */}
        <Form onSubmit={handlePurchase}>
          {/* Buy Fixed Amount Button */}
          <div className="d-flex justify-content-center mb-3">
            <Button
              variant="success"
              onClick={handleBuyFixedAmount}
              disabled={purchaseLoading || !creationFee || !tokenPrice}
              className="w-100 w-md-50 rounded-pill"
              aria-label={`Buy ${creationFee ? formatUnits(creationFee, decimals) : "..." } TBLUNT`}
            >
              {purchaseLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Processing...
                </>
              ) : (
                `Buy ${creationFee ? formatUnits(creationFee, decimals) : "..." } TBLUNT`
              )}
            </Button>
          </div>

          {/* Divider */}
          <div className="text-center mb-4 position-relative">
            <hr className="border-top border-secondary" />
            <span className="bg-dark px-2 position-absolute top-50 start-50 translate-middle">OR</span>
          </div>

          {/* Purchase Amount Input */}
          <Form.Group controlId="purchaseAmount" className="mb-4">
            <Form.Label className="text-center d-block mb-2" style={{ fontWeight: 'bold', color: '#a5d6a7' }}>Amount of TBLUNT</Form.Label>
            <InputGroup>
              <Form.Control
                type="number"
                placeholder="Enter number of TBLUNT"
                value={purchaseAmount}
                onChange={handlePurchaseAmountChange}
                min="0"
                step="any"
                required
                className="bg-dark text-light border-secondary text-center"
                disabled={purchaseLoading}
                aria-label="Amount of TBLUNT to Purchase"
              />
              <Button
                variant="secondary"
                onClick={() => {
                  if (tokenPrice && parseFloat(formatUnits(tokenPrice, "ether")) !== 0) {
                    const maxTokens = parseFloat(ethBalance) / parseFloat(formatUnits(tokenPrice, "ether"));
                    setPurchaseAmount(maxTokens.toFixed(6));
                    setEthAmount(ethBalance);
                  }
                }}
                disabled={purchaseLoading}
                title="Set to Max Affordable Tokens"
                className="rounded-end"
                aria-label="Set Maximum Affordable Tokens"
              >
                <FaShoppingCart />
              </Button>
            </InputGroup>
            {purchaseAmountError && (
              <Form.Text className="text-danger">{purchaseAmountError}</Form.Text>
            )}
          </Form.Group>

          {/* ETH Amount Input */}
          <Form.Group controlId="ethAmount" className="mb-4">
            <Form.Label className="text-center d-block mb-2" style={{ fontWeight: 'bold', color: '#a5d6a7' }}>Amount of ETH</Form.Label>
            <InputGroup>
              <Form.Control
                type="number"
                placeholder="Enter amount of ETH"
                value={ethAmount}
                onChange={handleEthAmountChange}
                min="0"
                step="any"
                required
                className="bg-dark text-light border-secondary text-center"
                disabled={purchaseLoading}
                max={ethBalance}
                aria-label="Amount of ETH to Spend"
              />
              <Button
                variant="secondary"
                onClick={() => {
                  if (
                    purchaseAmount &&
                    parseFloat(purchaseAmount) > 0 &&
                    tokenPrice &&
                    parseFloat(formatUnits(tokenPrice, "ether")) !== 0
                  ) {
                    const maxETH =
                      parseFloat(purchaseAmount) *
                      parseFloat(formatUnits(tokenPrice, "ether"));
                    setEthAmount(maxETH.toFixed(6));
                  }
                }}
                disabled={purchaseLoading}
                title="Set to ETH Equivalent of Purchase Amount"
                className="rounded-end"
                aria-label="Set ETH Equivalent of Purchase Amount"
              >
                <FaEthereum />
              </Button>
            </InputGroup>
            {ethAmountError && (
              <Form.Text className="text-danger">{ethAmountError}</Form.Text>
            )}
            {usdEstimate && (
              <Form.Text className="text-info">
                ≈ ${usdEstimate} USD
              </Form.Text>
            )}
          </Form.Group>

          {/* Purchase Button */}
          <div className="d-flex justify-content-center">
            <Button
              variant="primary"
              type="submit"
              disabled={purchaseLoading}
              className="w-100 w-md-50 rounded-pill"
              aria-label="Purchase TBLUNT Tokens"
            >
              {purchaseLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Processing...
                </>
              ) : (
                "Purchase TBLUNT"
              )}
            </Button>
          </div>

          {purchaseError && (
            <Alert variant="danger" className="mt-3 text-center">
              {purchaseError}
            </Alert>
          )}
        </Form>

        {/* Purchase Receipt Modal */}
        <Modal show={showReceipt} onHide={handleCloseReceipt} centered aria-labelledby="purchase-receipt-modal">
          <Modal.Header closeButton className="bg-dark text-light">
            <Modal.Title id="purchase-receipt-modal">Purchase Receipt</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-dark text-light">
            {receipt ? (
              <Container>
                <Row className="mb-2">
                  <Col><strong>Tokens Purchased:</strong></Col>
                  <Col className="text-end">{formatNumber(receipt.purchasedTokens)} {tokenSymbol}</Col>
                </Row>
                <Row className="mb-2">
                  <Col><strong>ETH Spent:</strong></Col>
                  <Col className="text-end">{formatNumber(receipt.spentETH)} ETH</Col>
                </Row>
                <Row className="mb-2">
                  <Col><strong>Transaction Hash:</strong></Col>
                  <Col className="text-end">
                    {receipt.txHash ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-txHash`}>{receipt.txHash}</Tooltip>}
                      >
                        <a
                          href={getExplorerUrl(receipt.txHash)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary"
                          style={{ wordBreak: "break-all", textDecoration: "underline" }}
                          aria-label="Transaction Hash Link"
                        >
                          {truncateHash(receipt.txHash)}
                        </a>
                      </OverlayTrigger>
                    ) : (
                      "Transaction hash unavailable."
                    )}
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col><strong>Block Number:</strong></Col>
                  <Col className="text-end">{receipt.blockNumber}</Col>
                </Row>
                <Row className="mb-2">
                  <Col><strong>Timestamp:</strong></Col>
                  <Col className="text-end">{receipt.timestamp}</Col>
                </Row>
              </Container>
            ) : (
              <p>No receipt information available.</p>
            )}
          </Modal.Body>
          <Modal.Footer className="bg-dark text-light">
            <Button variant="primary" onClick={handleCloseReceipt} aria-label="Close Receipt Modal">
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Persistent Receipt Display */}
        {receipt && (
          <Card className="p-4 mt-4 bg-dark text-light border-0 receipt-card">
            <Card.Body>
              <Card.Title className="text-center mb-4">Purchase Receipt</Card.Title>
              <Row className="mb-3">
                <Col xs={6}><strong>Tokens Purchased:</strong></Col>
                <Col xs={6} className="text-end">{formatNumber(receipt.purchasedTokens)} {tokenSymbol}</Col>
              </Row>
              <Row className="mb-3">
                <Col xs={6}><strong>ETH Spent:</strong></Col>
                <Col xs={6} className="text-end">{formatNumber(receipt.spentETH)} ETH</Col>
              </Row>
              <Row className="mb-3 align-items-center">
                <Col xs={6}><strong>Transaction Hash:</strong></Col>
                <Col xs={6} className="text-end d-flex align-items-center justify-content-end">
                  {receipt.txHash ? (
                    <>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-copyTxHash`}>Copy Transaction Hash</Tooltip>}
                      >
                        <a
                          href={getExplorerUrl(receipt.txHash)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary me-2 receipt-hash"
                          style={{
                            textDecoration: "underline",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "block",
                            maxWidth: "200px",
                            fontFamily: "'Courier New', Courier, monospace",
                          }}
                          aria-label="Transaction Hash Link"
                        >
                          {truncateHash(receipt.txHash)}
                        </a>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-copyTxHash`}>Copy Transaction Hash</Tooltip>}
                      >
                        <Button
                          variant="outline-light"
                          size="sm"
                          onClick={() => copyToClipboard(receipt.txHash)}
                          className={styles.interactorCopyButton}
                          aria-label="Copy Transaction Hash"
                        >
                          <FaCopy />
                        </Button>
                      </OverlayTrigger>
                    </>
                  ) : (
                    "Transaction hash unavailable."
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={6}><strong>Block Number:</strong></Col>
                <Col xs={6} className="text-end">{receipt.blockNumber}</Col>
              </Row>
              <Row className="mb-3">
                <Col xs={6}><strong>Timestamp:</strong></Col>
                <Col xs={6} className="text-end">{receipt.timestamp}</Col>
              </Row>

              {/* Add Token to Wallet Button */}
              <div className="d-flex justify-content-center mt-4">
                <Button variant="info" onClick={handleAddTokenToWallet} className="rounded-pill" aria-label={`Add ${tokenSymbol} to Wallet`}>
                  Add {tokenSymbol} to Wallet
                </Button>
              </div>
            </Card.Body>
          </Card>
        )}
      </Card.Body>
    </Card>
  );
};

export default BuyTbluntPanel;
