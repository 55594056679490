// src/components/ImageWithFallback.js

import React, { useState, useEffect } from 'react';

const ImageWithFallback = ({ src, alt, style, className }) => {
  const [imgSrc, setImgSrc] = useState('/images/image.png');

  useEffect(() => {
    let isMounted = true;
    const img = new Image();
    img.src = src;

    img.onload = () => {
      if (isMounted) {
        setImgSrc(src);
      }
    };

    img.onerror = () => {
      // You can log the error or handle it if needed
      console.error(`Failed to load image: ${src}`);
    };

    return () => {
      isMounted = false;
    };
  }, [src]);

  return <img src={imgSrc} alt={alt} style={style} className={className} />;
};

export default ImageWithFallback;
