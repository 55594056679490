// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Web3Provider from './Web3Context';
import { BrowserRouter as Router } from 'react-router-dom'; // Import Router here
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome for icons
import 'react-toastify/dist/ReactToastify.css'; // Import React Toastify styles
import './services/reownAppKitConfig'; // Import to initialize AppKit
import './App.scss'; // Import custom SCSS
import './index.css'; // Custom CSS

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
        <Web3Provider>
      <Router>
          <App />
      </Router>
        </Web3Provider>
  </React.StrictMode>
);
