// src/services/reownAppKitConfig.js

import { createAppKit } from '@reown/appkit/react';
import { EthersAdapter } from '@reown/appkit-adapter-ethers';
import { mainnet, base, baseSepolia } from '@reown/appkit/networks'; // Import essential networks

// 1. Get your project ID from Reown Cloud
const projectId = process.env.REACT_APP_PROJECT_ID || 'YOUR_PROJECT_ID'; // Replace with your actual Project ID

// 2. Define the essential networks
const networks = [
  mainnet,      // Ethereum Mainnet
  base,         // Base Mainnet
  baseSepolia,         // Base Mainnet
];

// 3. Create a metadata object - ensure 'url' matches your deployed domain
const metadata = {
  name: 'TurtleBlunt TokenCreator',
  description: 'Create Your Own Token On The Base Network',
  url: 'https://testnet.turtleblunt.com', // Replace with your actual domain
  icons: ['https://avatars.mywebsite.com/'], // Replace with your actual icon URLs
};

// 4. Initialize Reown AppKit with the essential networks
createAppKit({
  adapters: [new EthersAdapter()], // Ensure correct capitalization
  networks,                        // Use the predefined networks array
  defaultNetwork: baseSepolia,
  metadata,
  projectId,
  features: {
    analytics: true, // Optional - enables analytics
    swaps: true,    // Disable swaps feature if not needed
    onramp: true,   // Disable onramp feature if not needed
  },
});

// No need to create AppKitProvider here since it's not exported by the package
// Simply exporting nothing or a configuration utility if needed
