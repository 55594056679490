// src/components/TestnetInstructionsBar.js

import React, { useState, useEffect } from 'react';
import { Offcanvas, Button, ListGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaTimes, FaChevronRight, FaChevronLeft, FaNetworkWired } from 'react-icons/fa';
import './TestnetInstructionsBar.css'; // Import custom CSS

/**
 * TestnetInstructionsBar Component
 *
 * A collapsible sidebar that provides users with step-by-step instructions
 * to set up their environment and interact with the token.
 * Users can hide/show the bar, and the hidden state persists across page refreshes.
 */
const TestnetInstructionsBar = () => {
  // Initialize state from localStorage or default to true (open)
  const [show, setShow] = useState(() => {
    const savedState = localStorage.getItem('testnetBarOpen');
    return savedState !== null ? JSON.parse(savedState) : true;
  });

  // Function to handle opening the sidebar
  const handleShow = () => setShow(true);

  // Function to handle closing the sidebar
  const handleClose = () => setShow(false);

  // Toggle function to open/close the sidebar and save state to localStorage
  const toggleSidebar = () => {
    setShow((prev) => {
      localStorage.setItem('testnetBarOpen', JSON.stringify(!prev));
      return !prev;
    });
  };

  // Tooltip for the toggle button
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {show ? 'Hide Instructions' : 'Show Instructions'}
    </Tooltip>
  );

  return (
    <>
      {/* Offcanvas Sidebar */}
      <Offcanvas show={show} onHide={handleClose} placement="start" className="testnet-offcanvas">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="d-flex align-items-center">
            <FaNetworkWired className="me-2" size={20} />
            Base Sepolia Testnet Instructions
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ListGroup variant="flush">
            {/* Step 1: Add Base Sepolia Testnet */}
            <ListGroup.Item className="bg-dark text-light">
              <strong>1. Add Base Sepolia Testnet to Your Wallet</strong>
              <p>
                <strong>RPC URL:</strong>{' '}
                <a
                  href="https://sepolia.base.org"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-success"
                >
                  https://sepolia.base.org
                </a>
                <br />
                <strong>Chain ID:</strong> 84532
                <br />
                <strong>Network Name:</strong> Base Sepolia Testnet
              </p>
              <p>
                <em>Instructions to add the network to MetaMask:</em>
              </p>
              <ol>
                <li>Open MetaMask and click on the network dropdown at the top.</li>
                <li>Select "Add Network".</li>
                <li>Fill in the details provided above.</li>
                <li>Click "Save" to add the network.</li>
              </ol>
            </ListGroup.Item>

            {/* Step 2: Connect Your Wallet */}
            <ListGroup.Item className="bg-dark text-light">
              <strong>2. Connect Your Wallet</strong>
              <p>
                Ensure your wallet is connected to the Base Sepolia Testnet. Use the "Connect Wallet" button in the app to establish a connection.
              </p>
            </ListGroup.Item>

            {/* Step 3: Request Testnet Funds */}
            <ListGroup.Item className="bg-dark text-light">
              <strong>3. Request Testnet Funds</strong>
              <p>
                Navigate to the <strong>ETH Dispenser</strong> page and request testnet ETH to perform transactions.
              </p>
            </ListGroup.Item>

            {/* Step 4: Buy 100 TBlunt Tokens */}
            <ListGroup.Item className="bg-dark text-light">
              <strong>4. Buy 100 TBlunt Tokens</strong>
              <p>
                Go to the <strong>Home Page</strong> and purchase 100 TBlunt tokens using your testnet ETH.
              </p>
            </ListGroup.Item>

            {/* Step 5: Create Your Token */}
            <ListGroup.Item className="bg-dark text-light">
              <strong>5. Create Your Token</strong>
              <p>
                Use your 100 TBlunt tokens to create a new token. Follow the on-screen instructions to set token parameters.
              </p>
            </ListGroup.Item>

            {/* Step 6: Review & Customize Metadata */}
            <ListGroup.Item className="bg-dark text-light">
              <strong>6. Review & Customize Metadata</strong>
              <p>
                After creation, review your token's information and customize its metadata by adding images and descriptions.
              </p>
            </ListGroup.Item>

            {/* Step 7: Interact with Your Token */}
            <ListGroup.Item className="bg-dark text-light">
              <strong>7. Interact with Your Token</strong>
              <p>
                Visit the <strong>Interactor Page</strong> to manage and interact with your token's functionalities.
              </p>
            </ListGroup.Item>

            {/* Step 8: Buy Tokens from Listing Page */}
            <ListGroup.Item className="bg-dark text-light">
              <strong>8. Buy Tokens from Listing Page</strong>
              <p>
                Use your token to make purchases on the <strong>Listing Page</strong> and explore its utility.
              </p>
            </ListGroup.Item>
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Fixed Toggle Button */}
      <OverlayTrigger placement="right" overlay={renderTooltip}>
        <Button
          variant="warning"
          className={`testnet-toggle-button ${show ? 'button-hidden' : ''}`}
          onClick={toggleSidebar}
          aria-label={show ? 'Hide Instructions' : 'Show Instructions'}
          aria-expanded={show}
        >
          {show ? <FaTimes /> : <FaChevronRight />}
        </Button>
      </OverlayTrigger>
    </>
  );
};

export default TestnetInstructionsBar;
