// src/components/ListingPage/TokenCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Clipboard } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { copyToClipboard } from '../utils/clipboard';
import { toast } from 'react-toastify';
import ImageWithFallback from './ImageWithFallback';
import styles from './ListingPage.module.scss';
import { formatNumber } from '../utils/format';

const TokenCard = ({ token, getExplorerUrl }) => {
  const navigate = useNavigate();

  const handleCopy = (e) => {
    e.stopPropagation(); // Prevent triggering card click
    copyToClipboard(token.address);
    toast.success('Address copied to clipboard!', {
      toastId: `copy-address-${token.address}`,
    });
  };

  const handleBuy = (e) => {
    e.stopPropagation(); // Prevent triggering card click
    navigate(`/token-sale/${token.address}`);
  };

  const truncateHash = (hash) => {
    if (!hash) return "";
    return `${hash.substring(0, 6)}...${hash.substring(hash.length - 4)}`;
  };

  return (
    <div
      className={styles.tokenCard}
      onClick={() => navigate(`/token-sale/${token.address}`)}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter') navigate(`/token-sale/${token.address}`);
      }}
      aria-label={`View sale page for ${token.name}`}
    >
      {/* Token Image */}
      <ImageWithFallback
        src={token.imageUrl || '/images/default-token.png'}
        alt={`${token.name} logo`}
        className={styles.cardImage}
      />

      {/* Token Information */}
      <h3 className={styles.cardTitle}>{token.name}</h3>
      <p className={styles.cardText}>
        <strong>Symbol:</strong> {token.symbol}
      </p>
      <p className={styles.cardText}>
        <strong>Total Supply:</strong> {formatNumber(token.totalSupply)}
      </p>
      <p className={styles.cardAddress}>
        <a
          href={getExplorerUrl(token.address)}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`View ${token.name} address on explorer`}
        >
          {token.address}
        </a>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Copy Address</Tooltip>}
        >
          <Button
            variant="link"
            onClick={handleCopy}
            className={styles.copyButton}
            aria-label={`Copy address of ${token.name}`}
          >
            <Clipboard size={16} />
          </Button>
        </OverlayTrigger>
      </p>
      <Button
        variant="primary"
        className={styles.buyButton}
        onClick={handleBuy}
        aria-label={`Buy ${token.name} tokens`}
      >
        Buy Tokens
      </Button>
    </div>
  );
};

TokenCard.propTypes = {
  token: PropTypes.shape({
    address: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    totalSupply: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    imageUrl: PropTypes.string,
  }).isRequired,
  getExplorerUrl: PropTypes.func.isRequired,
};

export default React.memo(TokenCard);
