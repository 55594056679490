// src/components/CreateToken.js

import React, { useState, useEffect, useMemo } from "react";
import { Contract, parseUnits, formatUnits, Signature, Interface } from "ethers";
import { useWeb3 } from "../Web3Context";
import TokenCreationManagerAbi from '../abis/TokenCreationManagerAbi.json';
import TBLUNTAbi from '../abis/TbluntToken.json';
import {
  Card,
  Form,
  Button,
  Alert,
  Spinner,
  Modal,
  Container,
  Table,
  Row,
  Col
} from "react-bootstrap";
import TokenInfo from './TokenInfo'; 
import ContractFeaturesPanel from './ContractFeaturesPanel';
import BuyTbluntPanel from './BuyTbluntPanel';
import { copyToClipboard } from '../utils/clipboard';
import { FaPlusCircle } from 'react-icons/fa';
import './CreateTokenForm.scss';

const CreateToken = () => {
  const { 
    provider, 
    signer, 
    address, 
    numericChainId, 
    error: web3Error, 
    lastCreatedToken, 
    updateLastCreatedToken,
    clearLastCreatedToken 
  } = useWeb3();

  const tokenCreationManagerAddress = process.env.REACT_APP_TOKEN_CREATION_MANAGER_ADDRESS;
  const tbluntTokenAddress = process.env.REACT_APP_TBLUNT_TOKEN_ADDRESS;
  const TBLUNT_DECIMALS = 2;

  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [initialSupply, setInitialSupply] = useState("");
  const [decimals, setDecimals] = useState(4); 
  const [initialPrice, setInitialPrice] = useState("");
  const [creationFee, setCreationFee] = useState(null);
  const [tbluntBalance, setTbluntBalance] = useState(null);
  const [error, setError] = useState(web3Error);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [newTokenAddress, setNewTokenAddress] = useState(lastCreatedToken?.tokenAddress || null);

  const [transactionHash, setTransactionHash] = useState(lastCreatedToken?.transactionHash || '');
  const [widgetCode, setWidgetCode] = useState(lastCreatedToken?.widgetCode || '');
  const [tokensAfterTax, setTokensAfterTax] = useState("");

  const tokenCreationManagerContract = useMemo(() => {
    if (!signer || !tokenCreationManagerAddress) return null;
    try {
      return new Contract(tokenCreationManagerAddress, TokenCreationManagerAbi.abi, signer);
    } catch (error) {
      console.error("Error initializing TokenCreationManager contract:", error);
      setError("Error initializing TokenCreationManager contract.");
      return null;
    }
  }, [signer, tokenCreationManagerAddress]);

  const tbluntContract = useMemo(() => {
    if (!signer || !tbluntTokenAddress) return null;
    try {
      return new Contract(tbluntTokenAddress, TBLUNTAbi.abi, signer);
    } catch (error) {
      console.error("Error initializing TBLUNT contract:", error);
      setError("Error initializing TBLUNT contract.");
      return null;
    }
  }, [signer, tbluntTokenAddress]);

  useEffect(() => {
    if (!tbluntTokenAddress) {
      setError("TBLUNT Token Address is not defined. Please check your environment variables.");
    }
  }, [tbluntTokenAddress]);

  useEffect(() => {
    const fetchData = async () => {
      if (!tokenCreationManagerContract || !tbluntContract || !address) {
        return;
      }

      try {
        const fee = await tokenCreationManagerContract.creationFee();
        console.log("Fetched creationFee:", fee.toString());
        setCreationFee(fee);

        await fetchBalances();
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Error fetching data from contracts.");
      }
    };

    fetchData();
  }, [tokenCreationManagerContract, tbluntContract, address]);

  const fetchBalances = async () => {
    try {
      console.log("Fetching balances...");
      const tblunt = await tbluntContract.balanceOf(address);
      console.log("Raw TBLUNT Balance:", tblunt.toString());
      setTbluntBalance(tblunt);
    } catch (err) {
      console.error("Error fetching balances:", err);
      setError("Error fetching balances.");
    }
  };

  const handleReview = (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    if (!Number.isInteger(decimals) || decimals < 0) {
      setError("Decimals must be a non-negative integer.");
      return;
    }

    const initialPriceNumber = parseFloat(initialPrice);
    if (isNaN(initialPriceNumber) || initialPriceNumber <= 0) {
      setError("Initial Price must be a positive number.");
      return;
    }

    const initialSupplyNumber = parseFloat(initialSupply);
    if (isNaN(initialSupplyNumber) || initialSupplyNumber <= 0) {
      setError("Initial Supply must be a positive number.");
      return;
    }

    const initialSupplyBN = parseUnits(initialSupply, decimals);
    const tokensAfterTaxBN = (initialSupplyBN * 90n) / 100n; 
    const tokensAfterTaxFormatted = formatUnits(tokensAfterTaxBN, decimals);
    setTokensAfterTax(tokensAfterTaxFormatted);

    setShowReview(true);
  };

  const handleCreateToken = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      if (!signer || !address) {
        throw new Error("Wallet not connected.");
      }

      if (!tbluntBalance) {
        throw new Error("TBLUNT balance is unavailable.");
      }

      if (creationFee === null) {
        throw new Error("Token creation fee is unavailable.");
      }

      if (tbluntBalance < creationFee) {
        throw new Error("Insufficient TBLUNT balance to create token.");
      }

      if (!numericChainId || isNaN(numericChainId)) {
        throw new Error("Invalid chain ID. Please ensure you are connected to a valid network.");
      }

      const tbluntName = await tbluntContract.name();
      const tbluntPermit = {
        name: tbluntName,
        version: '1',
        chainId: numericChainId,
        verifyingContract: tbluntTokenAddress,
      };

      const types = {
        Permit: [
          { name: 'owner', type: 'address' },
          { name: 'spender', type: 'address' },
          { name: 'value', type: 'uint256' },
          { name: 'nonce', type: 'uint256' },
          { name: 'deadline', type: 'uint256' },
        ],
      };

      const nonce = await tbluntContract.nonces(address);
      const deadline = Math.floor(Date.now() / 1000) + 60 * 60;

      const values = {
        owner: address,
        spender: tokenCreationManagerAddress,
        value: creationFee.toString(),
        nonce: nonce,
        deadline: deadline,
      };

      const signature = await signer.signTypedData(tbluntPermit, types, values);
      const sig = Signature.from(signature);
      const { v, r, s } = sig;

      const parsedInitialSupply = parseUnits(initialSupply, decimals);
      const parsedInitialPrice = parseUnits(initialPrice, "ether");

      console.log("Parsed Initial Supply:", parsedInitialSupply.toString());
      console.log("Parsed Initial Price:", parsedInitialPrice.toString());

      const createTx = await tokenCreationManagerContract.createToken(
        name,
        symbol,
        parsedInitialSupply,
        decimals,
        parsedInitialPrice,
        deadline,
        v,
        r,
        s
      );

      console.log("Create token transaction sent:", createTx.hash);
      setTransactionHash(createTx.hash);

      const receipt = await createTx.wait();
      console.log("Create token transaction confirmed.", receipt);

      const iface = new Interface(TokenCreationManagerAbi.abi);
      let createdTokenAddress = null;

      for (const log of receipt.logs) {
        try {
          const parsedLog = iface.parseLog(log);
          if (parsedLog.name === 'TokenCreated') {
            createdTokenAddress = parsedLog.args.tokenAddress;
            console.log("TokenCreated event found:", parsedLog.args);
            break;
          }
        } catch (err) {
          continue;
        }
      }

      if (createdTokenAddress) {
        setNewTokenAddress(createdTokenAddress);

        const generatedWidgetCode = `<iframe src="${window.location.origin}/token-sale/${createdTokenAddress}" width="600" height="400" style="border: none;"></iframe>`;
        setWidgetCode(generatedWidgetCode);
        console.log('Generated Widget Code:', generatedWidgetCode);

        const tokenData = {
          name,
          symbol,
          initialSupply,
          decimals,
          tokenAddress: createdTokenAddress,
          transactionHash: createTx.hash,
          widgetCode: generatedWidgetCode,
        };
        updateLastCreatedToken(tokenData);
      } else {
        console.warn("TokenCreated event not found in transaction receipt.");
        setError("Token creation successful, but could not retrieve token address.");
      }

      setSuccess("Token created successfully!");
      setShowReview(false);
      await fetchBalances();
    } catch (err) {
      console.error("Error creating token:", err);
      setError(`Error creating token: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const formatBalance = (balance, decimals = 18, displayDecimals = 18) => {
    if (!balance) {
      return "0";
    }
    try {
      const formatted = formatUnits(balance, decimals);
      const decimalIndex = formatted.indexOf('.');
      if (decimalIndex === -1) return formatted;
      return formatted.slice(0, decimalIndex + displayDecimals + 1);
    } catch (err) {
      console.error("Error formatting balance:", err);
      return "0";
    }
  };

  const handleCreateAnother = () => {
    setName("");
    setSymbol("");
    setInitialSupply("");
    setDecimals(4);
    setInitialPrice("");
    setTokensAfterTax("");
    setNewTokenAddress(null);
    setTransactionHash('');
    setWidgetCode('');
    setSuccess(null);
    setError(null);
    setShowReview(false);
    clearLastCreatedToken();
  };

  return (
    <Container fluid className="mt-5 px-3">
      <Card className="bg-dark text-light mb-4 create-token-form">
        <Card.Body className="create-token-header">
          <img
            src="/images/Turtleblunt.png"
            alt="Create Token"
            style={{ maxWidth: "300px", height: "auto", marginBottom: "1rem", animation: "float 3s ease-in-out infinite" }}
          />
          <h2><FaPlusCircle style={{marginRight: "10px"}} />Create Your Custom Token</h2>
          <p className="text-light">Easily create and manage your own cryptocurrency tokens.</p>
        </Card.Body>
      </Card>

      {!lastCreatedToken ? (
        <div>
          <Row className="g-4 mb-4">
            <Col xs={12}>
              <ContractFeaturesPanel />
            </Col>
          </Row>

          <Row className="g-4">
            <Col xs={12} lg={6}>
              <BuyTbluntPanel />
            </Col>
            <Col xs={12} lg={6}>
              <Card className="p-4 bg-dark text-light w-100 h-100 create-token-form">
                <Card.Body>
                  {error && <Alert variant="danger">{error}</Alert>}
                  {success && <Alert variant="success">{success}</Alert>}

                  {!showReview ? (
                    <Form onSubmit={handleReview}>
                      <Form.Group controlId="tokenName" className="mb-3">
                        <Form.Label>Token Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter token name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </Form.Group>

                      <Form.Group controlId="tokenSymbol" className="mb-3">
                        <Form.Label>Token Symbol</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter token symbol"
                          value={symbol}
                          onChange={(e) => setSymbol(e.target.value)}
                          required
                        />
                      </Form.Group>

                      <Form.Group controlId="initialSupply" className="mb-3">
                        <Form.Label>Initial Supply</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter initial supply"
                          value={initialSupply}
                          onChange={(e) => setInitialSupply(e.target.value)}
                          required
                          min="0"
                          step="any"
                        />
                      </Form.Group>

                      <Form.Group controlId="decimals" className="mb-3">
                        <Form.Label>Decimals</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter decimals"
                          value={decimals}
                          onChange={(e) => setDecimals(Number(e.target.value))}
                          required
                          min="0"
                        />
                      </Form.Group>

                      <Form.Group controlId="initialPrice" className="mb-4">
                        <Form.Label>Initial Price (ETH)</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter initial price in ETH"
                          value={initialPrice}
                          onChange={(e) => setInitialPrice(e.target.value)}
                          required
                          min="0"
                          step="0.0000000000000001"
                        />
                      </Form.Group>

                      <Card className="bg-secondary text-light text-center mb-4">
                        <Card.Body>
                          <Card.Title>TBLUNT Balance</Card.Title>
                          <Card.Text>{formatBalance(tbluntBalance, TBLUNT_DECIMALS)} TBLUNT</Card.Text>
                        </Card.Body>
                      </Card>

                      <Card className="bg-secondary text-light text-center mb-4">
                        <Card.Body>
                          <Card.Title>Token Creation Fee</Card.Title>
                          <Card.Text>{formatBalance(creationFee, TBLUNT_DECIMALS)} TBLUNT</Card.Text>
                        </Card.Body>
                      </Card>

                      <Button variant="primary" type="submit" className="w-100">
                        Review
                      </Button>
                    </Form>
                  ) : (
                    <Modal show={showReview} onHide={() => setShowReview(false)} centered size="lg">
                      <Modal.Header closeButton>
                        <Modal.Title>Review Token Creation</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Table bordered variant="dark" className="text-center">
                          <tbody>
                            <tr>
                              <th>Token Name</th>
                              <td>{name}</td>
                            </tr>
                            <tr>
                              <th>Token Symbol</th>
                              <td>{symbol}</td>
                            </tr>
                            <tr>
                              <th>Initial Supply</th>
                              <td>{initialSupply}</td>
                            </tr>
                            <tr>
                              <th>Decimals</th>
                              <td>{decimals}</td>
                            </tr>
                            <tr>
                              <th>Initial Price</th>
                              <td>{initialPrice} ETH</td>
                            </tr>
                            <tr>
                              <th>Tokens After Tax (90%)</th>
                              <td>{tokensAfterTax} {symbol}</td>
                            </tr>
                            <tr>
                              <th>Token Creation Fee</th>
                              <td>{formatBalance(creationFee, TBLUNT_DECIMALS)} TBLUNT</td>
                            </tr>
                          </tbody>
                        </Table>
                        <Alert variant="info" className="text-center">
                          You will receive <strong>{tokensAfterTax} {symbol}</strong> after a 10% tax on the initial supply.
                          <br />
                          You are about to spend <strong>{formatBalance(creationFee, TBLUNT_DECIMALS)} TBLUNT</strong> to create this token.
                        </Alert>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={() => setShowReview(false)}
                          disabled={loading}
                        >
                          Back
                        </Button>
                        <Button
                          variant="primary"
                          onClick={handleCreateToken}
                          disabled={loading || (tbluntBalance && tbluntBalance < creationFee)}
                          className="w-100"
                        >
                          {loading ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                              />
                              Processing...
                            </>
                          ) : (
                            "Confirm and Create"
                          )}
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <Card className="p-4 bg-dark text-light create-token-form">
          <Card.Body>
            <Card.Title className="text-center mb-4">Token Created Successfully!</Card.Title>
            <TokenInfo
              name={lastCreatedToken.name}
              symbol={lastCreatedToken.symbol}
              initialSupply={lastCreatedToken.initialSupply}
              decimals={lastCreatedToken.decimals}
              newTokenAddress={lastCreatedToken.tokenAddress}
              transactionHash={lastCreatedToken.transactionHash}
              widgetCode={lastCreatedToken.widgetCode}
              copyToClipboard={copyToClipboard}
              chainId={numericChainId}
            />
            <Button variant="primary" className="create-another-btn" onClick={handleCreateAnother}>
              Create Another Token
            </Button>
          </Card.Body>
        </Card>
      )}
    </Container>
  );
};

export default CreateToken;
