// src/utils/clipboard.js

import { toast } from 'react-toastify';

// Robust copy-to-clipboard function with fallback
export const copyToClipboard = async (text) => {
  if (!text) return;

  // Check if navigator.clipboard is available
  if (navigator.clipboard && navigator.clipboard.writeText) {
    try {
      await navigator.clipboard.writeText(text);
      toast.success('Address copied to clipboard!', {
        toastId: 'copy-address-success',
      });
    } catch (err) {
      console.error('Failed to copy via navigator.clipboard.writeText:', err);
      fallbackCopyTextToClipboard(text);
    }
  } else {
    // Fallback for older browsers or if navigator.clipboard is not available
    fallbackCopyTextToClipboard(text);
  }
};

// Fallback method using a temporary textarea element
const fallbackCopyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Prevent scrolling to bottom
  textArea.style.position = 'fixed';
  textArea.style.top = '-9999px';
  textArea.style.left = '-9999px';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    if (successful) {
      toast.success('Address copied to clipboard!', {
        toastId: 'copy-address-success',
      });
    } else {
      throw new Error('Copy command was unsuccessful');
    }
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
    toast.error('Failed to copy address.', { toastId: 'copy-address-failed' });
  }

  document.body.removeChild(textArea);
};
