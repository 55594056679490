// src/utils/format.js

/**
 * Formats a number based on its size.
 * - 0-999: Display as-is.
 * - 1,000-999,999: Display in 'K' format.
 * - 1,000,000 and above: Display in 'M' format.
 *
 * @param {number|string} num - The number to format.
 * @returns {string} - The formatted number string.
 */
export const formatNumber = (num) => {
  if (typeof num === 'string') {
    num = parseFloat(num);
  }
  if (isNaN(num)) return '0';
  if (num < 1000) return num.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 6 });
  if (num >= 1000 && num < 1000000) {
    return `${(num / 1000).toFixed(1)}K`;
  }
  if (num >= 1000000 && num < 1000000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  }
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(1)}B`;
  }
  return num.toString();
};

/**
 * Formats a date string to "Month Day, Year"
 * Example: "2022-09-02" -> "September 2, 2022"
 *
 * @param {string} dateStr - The date string in ISO format.
 * @returns {string} - The formatted date string.
 */
export const formatDate = (dateStr) => {
  const dateObj = new Date(dateStr);
  if (isNaN(dateObj)) return 'Invalid Date';
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return dateObj.toLocaleDateString(undefined, options);
};
