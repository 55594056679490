// src/pages/PrivacyPolicy.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './LegalPages.scss'; // Shared styling for legal pages

const PrivacyPolicy = () => {
  return (
    <Container className="legal-container my-5">
      <Row className="justify-content-center">
        <Col md={10}>
          <Card className="bg-dark text-light p-4 border-0 shadow-lg">
            <Card.Body>
              <h1 className="text-center mb-4">Privacy Policy</h1>
              <p><strong>Effective Date:</strong> [Insert Date]</p>
              
              <h2 className="mt-5">No Personal Data Collected</h2>
              <p>We do not intentionally collect, process, or store personal data such as names, email addresses, or other identifying information. Accessing and using our site and services does not require any personal information.</p>

              <h2>Cookies and Tracking</h2>
              <p>We do not use cookies or similar technologies to track individual users. Your visit remains anonymous, and no personal profiling occurs.</p>

              <h2>Third-Party Links</h2>
              <p>Our site may contain links to third-party websites. We have no control over their content or practices and recommend reviewing their respective privacy policies.</p>

              <h2>Security</h2>
              <p>Since we do not collect personal data, security concerns are minimal. Any server logs or technical metadata are solely used for performance and security monitoring.</p>

              <h2>Changes to This Policy</h2>
              <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with a revised effective date.</p>

              <h2>Contact Us</h2>
              <p>If you have any questions, please contact us at: [Insert Contact Email or URL]</p>

              <p className="mt-4">By using our site, you acknowledge and accept this Privacy Policy.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
