// src/utils/addTokenToWallet.js

import { toast } from 'react-toastify';

/**
 * Adds an ERC20 token to the user's wallet using the wallet_watchAsset method.
 *
 * @param {Object} params - Parameters for adding the token.
 * @param {string} params.tokenAddress - The contract address of the token.
 * @param {string} params.tokenSymbol - The symbol of the token.
 * @param {number} params.tokenDecimals - The number of decimals the token uses.
 * @param {string} [params.tokenImage] - (Optional) URL to the token's logo image.
 */
export const addTokenToWallet = async ({
  tokenAddress,
  tokenSymbol,
  tokenDecimals,
  tokenImage = '',
}) => {
  if (window.ethereum && tokenAddress && tokenSymbol && tokenDecimals) {
    try {
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage, // Optional
          },
        },
      });

      if (wasAdded) {
        toast.success(`${tokenSymbol} has been added to your wallet!`, {
          toastId: 'add-token-success',
        });
      } else {
        toast.info('Token addition was rejected by the user.', {
          toastId: 'add-token-rejected',
        });
      }
    } catch (err) {
      console.error('Failed to add token:', err);
      toast.error(`Failed to add token to wallet: ${err.message}`, {
        toastId: 'add-token-error',
      });
    }
  } else {
    toast.error(
      'Unable to add token to wallet. Ensure you have a compatible wallet extension installed and the token details are correct.',
      { toastId: 'add-token-missing-info' }
    );
  }
};
