// src/pages/Disclaimer.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './LegalPages.scss';

const Disclaimer = () => {
  return (
    <Container className="legal-container my-5">
      <Row className="justify-content-center">
        <Col md={10}>
          <Card className="bg-dark text-light p-4 border-0 shadow-lg">
            <Card.Body>
              <h1 className="text-center mb-4">Disclaimer</h1>
              <p>
                The TBLUNT tokens and the services provided on this platform are for utility and informational purposes only. We do not offer financial advice, investment guarantees, or assurances of any token’s future value.
              </p>
              <p>
                Any reliance on the information, services, or tokens provided is at your own risk. You should always perform independent research or consult a qualified professional before making any financial decisions.
              </p>
              <p>
                By using our platform, you acknowledge and accept that we bear no responsibility for losses, damages, or outcomes resulting from your actions or decisions.
              </p>
              <p>
                Nothing on this site is intended to form a legal, financial, or investment contract. All content is provided “as is,” without warranties or guarantees of any kind.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Disclaimer;
